<div class="contacto">
    <div class="container-lg">
        <div class="my-container">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">{{ "Inicio" | uppercase }}</li>
                    <li class="breadcrumb-item quote-transform active" aria-current="page">
                        <a class="nav-item" role="button">{{ "Contacto" | uppercase}}</a>
                    </li>
                </ol>
            </nav>
            <div class="contacto-container">
                <div class="section-title-container">
                    <h2 class="contact-title">Contacto</h2>
                </div>
                <div class="section-body-container mt-0">
                    <div class="row content-header">
                        <div class="col-sm-12 mb-3">
                            <p class="content-text">
                                Mándenos un comentario para que nos comuniquemos con usted
                            </p>
                        </div>
                        <div class="col-sm-12">
                            <p class="content-text text-sky-blue">
                                *Todos los campos son obligatorios
                            </p>
                        </div>
                    </div>
                </div>
                <div class="section-body-container light-container">
                    <form #contactForm="ngForm" (ngSubmit)="sendContact()">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="section-title-container">
                                    <h3 class="section-title">{{ "Información de Contacto" | uppercase }}</h3>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="section-body-container">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <label for="name" class="group-title">Nombre</label>
                                                <input type="text" name="name" class="form-control" id="name"
                                                    [(ngModel)]="contact.name" #name="ngModel" required
                                                    autocomplete="name">
                                            </div>
                                        </div>
                                        <div class="col-lg-12">
                                            <div class="form-group">
                                                <label for="email" class="group-title">Correo Electrónico</label>
                                                <input type="email" name="email" class="form-control" id="email"
                                                    [(ngModel)]="contact.email" #email="ngModel" required
                                                    autocomplete="email">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="section-title-container">
                                    <h3 class="section-title">{{ "Su Mensaje" | uppercase }}</h3>
                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="section-body-container">
                                    <div class="form-group">
                                        <label for="subject" class="group-title">Asunto</label>
                                        <input type="text" name="subject" class="form-control" id="subject"
                                            [(ngModel)]="contact.subject" #subject="ngModel">
                                    </div>
                                    <div class="form-group">
                                        <label for="message" class="group-title">Mensaje</label>
                                        <textarea name="message" class="form-control p-3" id="message" rows="10"
                                            [(ngModel)]="contact.message" #subject="ngModel">
                                        </textarea>
                                    </div>
                                    <div class="form-check p-0">
                                        <div class="col-lg-6">
                                            <label for="send-copy" class="form-check-label content-text">
                                                ¿Enviarme una copia?
                                            </label>
                                            <input type="checkbox" name="send-copy" class="form-check-input"
                                                id="send-copy" [(ngModel)]="contact.resend" #resend="ngModel">
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div class="recaptcha captchaC">
                                <re-captcha (resolved)="resolved($event)"
                                    siteKey="6LfDQgYaAAAAAE1rayH1sASEI690yeBi55xclCmj"></re-captcha>
                            </div>
                            <div class="col-lg-12">
                                <div class="section-body-container">
                                    <div class="row">
                                        <div class="col-lg-12">
                                            <p class="content-text text-center">
                                                Por favor, seleccionar la casilla para continuar
                                            </p>
                                        </div>
                                        <div class="col-lg-12">

                                        </div>
                                        <div class="col-lg-12 text-center">
                                            <button type="submit" class="btn btn-primary btn-leer-mas"
                                                [disabled]="isSending || !validated">
                                                {{ "Enviar" | uppercase }}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="sede-container">
                    <div class="section-title-container py-5">
                        <h3 class="section-title">{{ "Sede Central" | uppercase }}</h3>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="card border-0">
                                <div class="card-header">
                                    <h2 class="info-title">Guatemala</h2>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-2">
                                            <img src="assets/svg/TelefonoAzul.svg" alt="Ícono de teléfono" width="20">
                                        </div>
                                        <div class="col-10">
                                            <p class="info-text">
                                                (502) 2424-4600
                                            </p>
                                        </div>
                                        <div class="col-2">
                                            <img src="assets/svg/FaxAzul.svg" alt="Ícono de Fax" width="20">
                                        </div>
                                        <div class="col-10">
                                            <p class="info-text">
                                                (502) 2424-4610
                                            </p>
                                        </div>
                                        <div class="col-2">
                                            <img src="assets/svg/EmailAzul.svg" alt="Ícono de Email" width="19">
                                        </div>
                                        <div class="col-10">
                                            <p class="info-text">
                                                <span class="text-sky-blue">
                                                    guatemala@parlacen.int
                                                </span>
                                            </p>
                                        </div>
                                        <div class="col-2">
                                            <img src="assets/svg/UbicacionAzul.svg" alt="Ícono de Ubicación" width="18">
                                        </div>
                                        <div class="col-10">
                                            <p class="info-text">
                                                12 avenida 33-04 zona 05 <br>
                                                <span class="text-sky-blue enhanced-text">
                                                    Ciudad de Guatemala, Guatemala, 01005 C. A.
                                                </span>
                                            </p>
                                        </div>
                                        <div class="col-2">
                                            <img src="assets/svg/HorarioAzul.svg" alt="Ícono de Horario" width="18">
                                        </div>
                                        <div class="col-10">
                                            <p class="info-text">
                                                Luneas a Viernes <br>
                                                <span class="text-sky-blue enhanced-text">
                                                    8:00 a las 16:00 hrs.
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="card border-0">
                                <div class="card-header">
                                </div>
                                <div class="card-body text-center">
                                    <img src="assets/img/photo4979118499666438470.png" alt="Imagen de la Ubicación"
                                        class="w-100 location-img">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="section-title-container py-5">
                        <h3 class="section-title">{{ "Sub Sedes" | uppercase }}</h3>
                    </div>
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="card border-0">
                                <div class="card-header">
                                    <h2 class="info-title">El Salvador</h2>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-2">
                                            <img src="assets/svg/TelefonoAzul.svg" alt="Ícono de teléfono" width="20">
                                        </div>
                                        <div class="col-10">
                                            <p class="info-text">
                                                (503) 2221-1360 / 64 / 68
                                            </p>
                                        </div>
                                        <div class="col-2">
                                            <img src="assets/svg/FaxAzul.svg" alt="Ícono de Fax" width="20">
                                        </div>
                                        <div class="col-10">
                                            <p class="info-text">
                                                (503) 2221-1375
                                            </p>
                                        </div>
                                        <div class="col-2">
                                            <img src="assets/svg/UbicacionAzul.svg" alt="Ícono de Ubicación" width="18">
                                        </div>
                                        <div class="col-10">
                                            <p class="info-text">
                                                15 calle poniente, entre la 1a. y 3a. avenida norte, número 205<br>
                                                <span class="text-sky-blue enhanced-text">
                                                    San Salvador, El Salvador
                                                </span>
                                            </p>
                                        </div>
                                        <div class="col-2">
                                            <img src="assets/svg/HorarioAzul.svg" alt="Ícono de Horario" width="18">
                                        </div>
                                        <div class="col-10">
                                            <p class="info-text">
                                                Luneas a Viernes <br>
                                                <span class="text-sky-blue enhanced-text">
                                                    8:00 a las 16:00 hrs.
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="card border-0">
                                <div class="card-header">
                                    <h2 class="info-title">Honduras</h2>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-2">
                                            <img src="assets/svg/TelefonoAzul.svg" alt="Ícono de teléfono" width="20">
                                        </div>
                                        <div class="col-10">
                                            <p class="info-text">
                                                (504) 2236-7766
                                            </p>
                                        </div>
                                        <div class="col-2">
                                            <img src="assets/svg/FaxAzul.svg" alt="Ícono de Fax" width="20">
                                        </div>
                                        <div class="col-10">
                                            <p class="info-text">
                                                (504) 2221-3213 / 3229 / 3238
                                            </p>
                                        </div>
                                        <div class="col-2">
                                            <img src="assets/svg/EmailAzul.svg" alt="Ícono de Email" width="19">
                                        </div>
                                        <div class="col-10">
                                            <p class="info-text">
                                                <span class="text-sky-blue">
                                                    honduras@parlacen.int
                                                </span>
                                            </p>
                                        </div>
                                        <div class="col-2">
                                            <img src="assets/svg/UbicacionAzul.svg" alt="Ícono de Ubicación" width="18">
                                        </div>
                                        <div class="col-10">
                                            <p class="info-text">
                                                Colonia Lara, 3a. calle, casa #727, una calle a la izquierda después
                                                del instituto nacional del TORAX.<br>
                                                <span class="text-sky-blue enhanced-text">
                                                    Tegucigalpa MDC, Honduras, CA
                                                </span>
                                            </p>
                                        </div>
                                        <div class="col-2">
                                            <img src="assets/svg/HorarioAzul.svg" alt="Ícono de Horario" width="18">
                                        </div>
                                        <div class="col-10">
                                            <p class="info-text">
                                                Luneas a Viernes <br>
                                                <span class="text-sky-blue enhanced-text">
                                                    8:00 a las 16:00 hrs.
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="card border-0">
                                <div class="card-header">
                                    <h2 class="info-title">Nicaragua</h2>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-2">
                                            <img src="assets/svg/TelefonoAzul.svg" alt="Ícono de teléfono" width="20">
                                        </div>
                                        <div class="col-10">
                                            <p class="info-text">
                                                (505) 2254-5461 / 2268-6049
                                            </p>
                                        </div>
                                        <div class="col-2">
                                            <img src="assets/svg/FaxAzul.svg" alt="Ícono de Fax" width="20">
                                        </div>
                                        <div class="col-10">
                                            <p class="info-text">
                                                (505) 2268-6534
                                            </p>
                                        </div>
                                        <!--div class="col-2">
                                            <img src="assets/svg/EmailAzul.svg" alt="Ícono de Email" width="19">
                                        </div>
                                        <div class="col-10">
                                            <p class="info-text">
                                                <span class="text-sky-blue">
                                                    nicaragua@parlacen.int
                                                </span>
                                            </p>
                                        </div -->
                                        <div class="col-2">
                                            <img src="assets/svg/UbicacionAzul.svg" alt="Ícono de Ubicación" width="18">
                                        </div>
                                        <div class="col-10">
                                            <p class="info-text">
                                                Entrada al Hospital Militar 2 calle al lago 1/2 calle abajo, contiguo al
                                                CNU, Reparto Bolonia<br>
                                                <span class="text-sky-blue enhanced-text">
                                                    Managua, Nicaragua, CA
                                                </span>
                                            </p>
                                        </div>
                                        <div class="col-2">
                                            <img src="assets/svg/HorarioAzul.svg" alt="Ícono de Horario" width="18">
                                        </div>
                                        <div class="col-10">
                                            <p class="info-text">
                                                Luneas a Viernes <br>
                                                <span class="text-sky-blue enhanced-text">
                                                    8:00 a las 16:00 hrs.
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="card border-0">
                                <div class="card-header">
                                    <h2 class="info-title">Panamá</h2>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-2">
                                            <img src="assets/svg/TelefonoAzul.svg" alt="Ícono de teléfono" width="20">
                                        </div>
                                        <div class="col-10">
                                            <p class="info-text">
                                                (507) 314-1415 / 1449
                                            </p>
                                        </div>
                                        <div class="col-2">
                                            <img src="assets/svg/FaxAzul.svg" alt="Ícono de Fax" width="20">
                                        </div>
                                        <div class="col-10">
                                            <p class="info-text">
                                                (507) 314-1415
                                            </p>
                                        </div>
                                        <div class="col-2">
                                            <img src="assets/svg/EmailAzul.svg" alt="Ícono de Email" width="19">
                                        </div>
                                        <div class="col-10">
                                            <p class="info-text">
                                                <span class="text-sky-blue">
                                                    parlacen@hotmail.com
                                                </span>
                                            </p>
                                        </div>
                                        <div class="col-2">
                                            <img src="assets/svg/UbicacionAzul.svg" alt="Ícono de Ubicación" width="18">
                                        </div>
                                        <div class="col-10">
                                            <p class="info-text">
                                                Vía Argentina, calle Alberto Navarro, edificio Raquel piso 2<br>
                                                <span class="text-sky-blue enhanced-text">
                                                    Panamá, República de Panamá,CA
                                                </span>
                                            </p>
                                        </div>
                                        <div class="col-2">
                                            <img src="assets/svg/HorarioAzul.svg" alt="Ícono de Horario" width="18">
                                        </div>
                                        <div class="col-10">
                                            <p class="info-text">
                                                Luneas a Viernes <br>
                                                <span class="text-sky-blue enhanced-text">
                                                    8:00 a las 16:00 hrs.
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="card border-0 special-width">
                                <div class="card-header">
                                    <h2 class="info-title">República Dominicana</h2>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-2">
                                            <img src="assets/svg/TelefonoAzul.svg" alt="Ícono de teléfono" width="20">
                                        </div>
                                        <div class="col-10">
                                            <p class="info-text">
                                                (1-809) 688-3441 / 42
                                            </p>
                                        </div>
                                        <div class="col-2">
                                            <img src="assets/svg/FaxAzul.svg" alt="Ícono de Fax" width="20">
                                        </div>
                                        <div class="col-10">
                                            <p class="info-text">
                                                (1-809) 687-1277
                                            </p>
                                        </div>
                                        <div class="col-2">
                                            <img src="assets/svg/EmailAzul.svg" alt="Ícono de Email" width="19">
                                        </div>
                                        <div class="col-10">
                                            <p class="info-text">
                                                <span class="text-sky-blue">
                                                    parlacen-rd@hotmail.com
                                                </span>
                                            </p>
                                        </div>
                                        <div class="col-2">
                                            <img src="assets/svg/UbicacionAzul.svg" alt="Ícono de Ubicación" width="18">
                                        </div>
                                        <div class="col-10">
                                            <p class="info-text">
                                                Calle Guacanagarix #4 esquina Maireni, Los Cacicazgos<br>
                                                <span class="text-sky-blue enhanced-text">
                                                    Santo Domingo, República Dominicana, CA
                                                </span>
                                            </p>
                                        </div>
                                        <div class="col-2">
                                            <img src="assets/svg/HorarioAzul.svg" alt="Ícono de Horario" width="18">
                                        </div>
                                        <div class="col-10">
                                            <p class="info-text">
                                                Luneas a Viernes <br>
                                                <span class="text-sky-blue enhanced-text">
                                                    8:00 a las 16:00 hrs.
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-spinner [loading]="isSending"></app-spinner>