<div class="acerca">
    <div id="carouselIndicators" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
            <li data-target="#carouselIndicators" data-slide-to="0" class="active"></li>
            <li data-target="#carouselIndicators" data-slide-to="1"></li>
            <li data-target="#carouselIndicators" data-slide-to="2"></li>
        </ol>
        <div class="carousel-inner">
            <div class="carousel-item active">
                <img src="assets/img/carusel-1.jpEg" class="d-block w-100 carousel-img"
                    srcset="assets/img/carusel-1.jpeg 1x, assets/img/carusel-1.jpeg 2x" data-interval="10000"
                    alt="JD-2023-2024" loading="lazy">
            </div>
            <div class="carousel-item">
                <img src="assets/img/carusel-2.jpeg" class="d-block w-100 carousel-img"
                    srcset="assets/img/carusel-2.jpeg, assets/img/carusel-2.jpeg 2x" data-interval="10000"
                    alt="JD-2023-2024" loading="lazy">
            </div>
            <div class="carousel-item">
                <img src="assets/img/carusel-3.jpeg" class="d-block w-100 carousel-img"
                    srcset="assets/img/carusel-3.jpeg 1x, assets/img/carusel-3.jpeg 2x"
                    data-interval="10000" alt="JD-2023-2024" loading="lazy">
            </div>
        </div>
        <a class="carousel-control-prev" href="#carouselIndicators" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselIndicators" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a>
        <div class="carousel-text d-lg-block">
            <h3 class="display-title">{{ "about.carusel.title" | translate }}</h3>
            <p class="display-text">
                {{ "about.carusel.text" | translate }}
            </p>
            <button type="button" class="btn btn-primary btn-leer-mas buttonHome" routerLink="/historia">
                {{ "about.carusel.btn" | translate | uppercase }}
            </button>
        </div>
    </div>

    <div class="container-lg">
        <div class="my-container">
            <nav aria-label="breadcrumb" *ngIf="bodySize >= 991">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">{{"about.breadcum.home"| translate |uppercase}}</li>
                    <li class="breadcrumb-item active" aria-current="page">
                        <a class="nav-item" role="button">
                            {{ "about.breadcum.about" | translate | uppercase }}
                        </a>
                    </li>
                </ol>
            </nav>
            <div class="acerca-del-parlacen">
                <h3>{{ "about.about.title" | translate | uppercase }}</h3>
            </div>
            <div class="section-body-container">
                <div class="row mision-vision text-center">
                    <div class="col-lg-6">
                        <img src="assets/svg/MisionAzul.svg" class="mision-img" alt="Ícono de Misión" width="30">
                        <h2 class="mision-h" loading="lazy">{{ "about.about.mission_title" | translate }}</h2>
                        <p class="mision-t">
                            {{ "about.about.mission_text" | translate }}
                        </p>
                    </div>
                    <div class="col-lg-6">
                        <img src="assets/svg/VisionAzul.svg" class="vision-img" alt="Ícono de Visión" width="30">
                        <h2 class="vision-h" loading="lazy">{{ "about.about.view_title" | translate }}</h2>
                        <p class="vision-t">
                            {{ "about.about.view_text" | translate }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-flex">
        <div class="objetivos-especificos">
            <div class="section-body-container">
                <div class="row objetivos-especificos">
                    <div class="col-lg-6 text-light align-self-center">
                        <div class="objetivos pl-50">
                            <h3 class="objetivos-h mb-4">
                                {{ "about.object.title" | translate }}
                            </h3>
                            <table class="objetivos-t">
                                <thead class="sr-only">
                                    <th scope="col">#</th>
                                    <th scope="col">Objetivo</th>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td class="vertical-align-baseline sucesion">1.</td>
                                        <td class="objetivo">
                                            {{ "about.object.text_1" | translate }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="vertical-align-baseline sucesion pt-4">2.</td>
                                        <td class="objetivo pt-4">
                                            {{ "about.object.text_2" | translate }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-lg-6 pr-0 pl-0">
                        <img src="assets/img/home-acerca-de.jpg" alt="Manos arriba" class="objetivos-img"
                            srcset="assets/img/home-acerca-de.jpg 1x, assets/img/home-acerca-de.jpg 2x" width="100%" loading="lazy">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-lg">
        <div class="my-container">
            <div class="novedades">
                <h3>{{ "about.news.title" | translate }}</h3>
            </div>
            <div class="section-body-container">
                <div class="row historia-marco-documentos text-center">
                    <div class="col-lg-4">
                        <div class="card">
                            <div class="card-body">
                                <img src="assets/svg/HistoriaAzul.svg" class="historia-img align-self-center"
                                    alt="Ícono de Historia" width="30" loading="lazy">
                                <h2 class="historia-parlacen-h">
                                    {{ "about.news.history_of_the_central_american_parliament" | translate }}
                                </h2>
                            </div>
                            <div class="card-footer">
                                <p class="historia-parlacen-t">
                                    <button type="button" class="btn btn-outline-dark outline-leer-mas">
                                        <a class="nav-link" routerLink="/historia">
                                            {{ "about.news.btn" | translate | uppercase }}
                                        </a>
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="card">
                            <div class="card-body">
                                <img src="assets/svg/MarcoJuridicoAzul.svg" class="marco-img align-self-center"
                                    alt="Ícono de Marco Jurídico y Político" width="43" loading="lazy">
                                <h2 class="marco-pj-h">{{ "about.news.political_and_legal_framework" | translate }}</h2>
                            </div>
                            <div class="card-footer">
                                <p class="marco-pj-t">
                                    <button type="button" class="btn btn-outline-dark outline-leer-mas">
                                        <a class="nav-link" routerLink="/marco-politico-juridico">
                                            {{ "about.news.btn" | translate | uppercase }}
                                        </a>
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="card">
                            <div class="card-body">
                                <img src="assets/svg/DocumentosAzul.svg" class="documentos-img align-self-center"
                                    alt="Ícono de Documentos Parlamentaria" width="34" loading="lazy">
                                <h2 class="docs-plenaria-h">{{ "about.news.plenary_documents" | translate }}</h2>
                            </div>
                            <div class="card-footer">
                                <p class="docs-plenaria-t">
                                    <button type="button" class="btn btn-outline-dark outline-leer-mas"
                                        routerLink="/resoluciones">
                                        <a class="nav-link">{{ "about.news.btn" | translate |uppercase }}</a>
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="atribuciones-parlacen">
                    <div class="accordion" id="accordion">
                        <div class="card atr-h">
                            <div class="card-header" id="headingOne">
                                <h2 class="mb-0">
                                    <button class="btn btn-link btn-block text-left dropdown-btn-atr" type="button"
                                        data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                                        aria-controls="collapseOne">
                                        {{ "about.powers_of_the_central_american_parliament.title" | translate }}
                                        <img src="assets/svg/DropdownCeleste.svg" class="float-right mt-2"
                                            alt="Ícono de desplegar" width="25" loading="lazy">
                                    </button>
                                </h2>
                            </div>

                        </div>
                    </div>
                    <div id="collapseOne" class="collapse show mt-3" aria-labelledby="headingOne"
                        data-parent="#accordion">
                        <div class="card info-text">
                            <div class="card-header">
                                <div class="row">
                                    <div class="col-lg-6 p-lg-5 py-3">
                                        <p>
                                            {{ "about.powers_of_the_central_american_parliament.text_1" | translate }}
                                        <p>
                                            {{ "about.powers_of_the_central_american_parliament.text_2" | translate }}
                                        </p>
                                        <p>
                                            {{ "about.powers_of_the_central_american_parliament.text_3" | translate }}
                                        </p>
                                        <p>
                                            {{ "about.powers_of_the_central_american_parliament.text_4" | translate}}
                                        </p>
                                        <p>
                                            {{ "about.powers_of_the_central_american_parliament.text_5" | translate}}
                                        </p>
                                    </div>
                                    <div class="col-lg-6 p-lg-5 py-3">
                                        <p>
                                            {{ "about.powers_of_the_central_american_parliament.text_6" | translate}}
                                        </p>
                                        <p>
                                            {{ "about.powers_of_the_central_american_parliament.text_7" | translate}}
                                        </p>
                                        <p>
                                            {{ "about.powers_of_the_central_american_parliament.text_8" | translate}}
                                        </p>
                                        <p>
                                            {{ "about.powers_of_the_central_american_parliament.text_9" | translate}}
                                        </p>
                                        <p>
                                            {{"about.powers_of_the_central_american_parliament.text_10"| translate}}
                                        </p>
                                        <p>
                                            {{"about.powers_of_the_central_american_parliament.text_11"| translate}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="proximas-actividades">
                <h3>{{"about.next_activities.title"| translate |uppercase}}</h3>
            </div>
            <div class="section-body-container">
                <div class="exito">
                    <div class="row text-white">
                        <div class="col-lg-6 text-center align-self-center exito-text">
                            <p class="prox-actividades-t">{{ "about.next_activities.txt" | translate }}
                            </p>
                            <button class="text-white" type="button" dowlo
                                class="btn btn-primary ti-btn prox-actividades-btn buttonHome">
                                <a class="text-white" href="assets/pdf/PC _ CASOS DE EXITO.pdf"
                                    download="Avances-Logrados-Parlacen" target="blank">{{"about.next_activities.btn"|
                                    translate |uppercase}}</a>
                            </button>
                        </div>
                        <div class="col-lg-6 p-0">
                            <img src="assets/img/Home-2.png" width="100%" class="ti-img"
                                alt="Diputados del Parlamento Centroamericano" loading="lazy"
                                srcset="assets/img/Home-2.png 1x, assets/img/Home-2.png 2x">
                        </div>
                    </div>
                </div>
            </div>
            <div class="sede-subsedes">
                <h3>{{ "about.campus.title" | translate | uppercase }}</h3>
            </div>
            <div class="section-body-container">
                <div class="sede-subsedes-info">
                    <div class="row group-title d-responsive">
                        <div class="col-lg-2">
                            <div class="sede-h">
                                <p>{{ "about.campus.campus" | translate }}</p>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div class="pais">
                                <p>{{ "about.campus.country" | translate }}</p>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="direccion">
                                <p>{{ "about.campus.address" | translate }}</p>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div class="contacto-tel">
                                <p>{{ "about.campus.telephonecontact" | translate }}</p>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="correo-e">
                                <p>{{ "about.campus.email" | translate }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row sede">
                        <div class="col-lg-12 p-0 row sede-h">
                            <div class="col-lg-12">
                                {{ "Sede" | uppercase }}
                            </div>
                        </div>
                        <div class="col-lg-12 p-0 row sede-c">
                            <div class="col-lg-2">
                                <p>Guatemala</p>
                            </div>
                            <div class="col-lg-2">
                                <p>Guatemala</p>
                            </div>
                            <div class="col-lg-3">
                                <p>
                                    12 avenida 33-04 zona 05<br>
                                    <span class="text-sky-blue">Ciudad de Guatemala, Guatemala, 01005, C. A.</span>
                                </p>
                            </div>
                            <div class="col-lg-2">
                                <p class="d-flex">
                                    <img src="assets/svg/TelefonoAzul.svg" class="align-self-baseline"
                                        alt="Ícono Teléfono" width="20" loading="lazy">
                                    (502) 2424-4600
                                </p>
                                <p class="d-flex">
                                    <img src="assets/svg/FaxAzul.svg" class="align-self-baseline" alt="Ícono Fax"
                                        width="20" loading="lazy">
                                    (502) 2424-4610
                                </p>
                            </div>
                            <div class="col-lg-3">
                                <div class="text-sky-blue">
                                    <p>guatemala@parlacen.int</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row subsede">
                        <div class="col-lg-12 p-0 row subsede-h">
                            <div class="col-lg-12">
                                {{ "Subsede" | uppercase }}
                            </div>
                        </div>
                        <div class="col-lg-12 p-0 row subsede-c">
                            <div class="col-lg-2">
                                <p>El Salvador</p>
                            </div>
                            <div class="col-lg-2">
                                <p>San Salvador</p>
                            </div>
                            <div class="col-lg-3">
                                <p>
                                    15 calle poniente, entre la 1a. y 3a. avenida norte, número 205<br>
                                    <span class="text-sky-blue">San Salvador, El Salvador</span>
                                </p>
                            </div>
                            <div class="col-lg-2">
                                <p class="d-flex">
                                    <img src="assets/svg/TelefonoAzul.svg" class="align-self-baseline"
                                        alt="Ícono Teléfono" width="20" loading="lazy">
                                    (503) 2221-1360 / 64 / 68
                                </p>
                                <p class="d-flex">
                                    <img src="assets/svg/FaxAzul.svg" class="align-self-baseline" alt="Ícono Fax"
                                        width="20" loading="lazy">
                                    (503) 2221-1375
                                </p>
                            </div>
                            <div class="col-lg-3"></div>
                        </div>
                        <div class="col-lg-12 p-0 row subsede-c">
                            <div class="col-lg-2">
                                <p>Honduras</p>
                            </div>
                            <div class="col-lg-2">
                                <p>Tegucigalpa</p>
                            </div>
                            <div class="col-lg-3">
                                <p>
                                    Colonia Lara, 3a. calle, casa #727, una Calle a la Izquierda Despúes Del Instituto
                                    Nacional del TORAX.<br>
                                    <span class="text-sky-blue">Tegucigalpa MDC, Honduras</span>
                                </p>
                            </div>
                            <div class="col-lg-2">
                                <p class="d-flex">
                                    <img src="assets/svg/TelefonoAzul.svg" class="align-self-baseline"
                                        alt="Ícono Teléfono" width="20" loading="lazy">
                                    (504) 2236-7766
                                </p>
                                <p class="d-flex">
                                    <img src="assets/svg/FaxAzul.svg" class="align-self-baseline" alt="Ícono Fax"
                                        width="20" loading="lazy">
                                    (504) 2221-3213/3229/3238
                                </p>
                            </div>
                            <div class="col-lg-3">
                                <div class="text-sky-blue">
                                    <p>honduras@parlacen.int</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 p-0 row subsede-c">
                            <div class="col-lg-2">
                                <p>Nicaragua</p>
                            </div>
                            <div class="col-lg-2">
                                <p>Managua</p>
                            </div>
                            <div class="col-lg-3">
                                <p>
                                    Entrada al Hospital Militar 2 Calle al Lago 1/2 calle Abajo, Contiguo al CNU,
                                    Reparto Bolonia<br>
                                    <span class="text-sky-blue">Managua, Nicaragua</span>
                                </p>
                            </div>
                            <div class="col-lg-2">
                                <p class="d-flex">
                                    <img src="assets/svg/TelefonoAzul.svg" class="align-self-baseline"
                                        alt="Ícono Teléfono" width="20" loading="lazy">
                                    (505) 2254-5461 / 2268-6049
                                </p>
                                <p>
                                    <img src="assets/svg/FaxAzul.svg" class="align-self-baseline" alt="Ícono Fax"
                                        width="20" loading="lazy">
                                    (505) 2268-6534
                                </p>
                            </div>
                            <div class="col-lg-3">
                                <div class="text-sky-blue">
                                    <p> </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 p-0 row subsede-c">
                            <div class="col-lg-2">
                                <p>Panamá</p>
                            </div>
                            <div class="col-lg-2">
                                <p>Panamá</p>
                            </div>
                            <div class="col-lg-3">
                                <p>
                                    Vía Argentina, calle Alberto Navarro, edificio Raquel piso 2<br>
                                    <span class="text-sky-blue">Panamá, Panamá</span>
                                </p>
                            </div>
                            <div class="col-lg-2">
                                <p class="d-flex">
                                    <img src="assets/svg/TelefonoAzul.svg" class="align-self-baseline"
                                        alt="Ícono Teléfono" width="20" loading="lazy">
                                    (507) 314-1415/ 1449
                                </p>
                                <p>
                                    <img src="assets/svg/FaxAzul.svg" class="align-self-baseline" alt="Ícono Fax"
                                        width="20" loading="lazy">
                                    (507) 314-1415
                                </p>
                            </div>
                            <div class="col-lg-3">
                                <div class="text-sky-blue">
                                    <p>parlacen@hotmail.com</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12 p-0 row subsede-c">
                            <div class="col-lg-2">Repúplica Dominicana</div>
                            <div class="col-lg-2">Santo Domingo</div>
                            <div class="col-lg-3">
                                <p>
                                    Calle Guacanagarix #4 esquina Maireni, Los Cacicazgos <br>
                                    <span class="text-sky-blue">Santo Domingo, República Dominicana, CA</span>
                                </p>
                            </div>
                            <div class="col-lg-2">
                                <p class="d-flex">
                                    <img src="assets/svg/TelefonoAzul.svg" class="align-self-baseline"
                                        alt="Ícono Teléfono" width="20" loading="lazy">
                                    (1-809) 688-3441 / 42
                                </p>
                                <p class="d-flex">
                                    <img src="assets/svg/FaxAzul.svg" class="align-self-baseline" alt="Ícono Fax"
                                        width="20" loading="lazy">
                                    (1-809) 687-1277
                                </p>
                            </div>
                            <div class="col-lg-3">
                                <div class="text-sky-blue">
                                    <p> parlacen-rd@hotmail.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="languages"class="additional-resources">
                <h3>{{"about.additional_resource.title"| translate |uppercase}}</h3>
            </div>
            <div id="lenguajes" class="section-body-container">
                <div class="resources">
                    <div class="row mr-0 mb-4">
                        <div class="col-lg-3 language">Español</div>
                        <div class="col-lg-9">
                            <div class="row mr-0">
                                <div class="col-lg-7 language-t">
                                    <p>Parlamento Centroamericano</p>
                                </div>
                                <div class="col-lg-5 text-center language-b">
                                    <p>
                                        <button type="button" class="btn btn-outline-primary"> <a
                                                href="assets/pdf/espanol.pdf">{{ "Descargar" | uppercase }}</a></button>
                                    </p>
                                </div>
                            </div>
                            <div class="row mr-0">
                                <div class="col-lg-7 language-t">
                                    <p>Parlamento Centroamericano - PARLACEN</p>
                                </div>
                                <div class="col-lg-5 text-center language-b">
                                    <p>
                                        <button type="button" class="btn btn-outline-primary"> <a
                                                href="assets/pdf/Presentación-Español-2021.pdf">{{ "Descargar"
                                                | uppercase }}</a></button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--
                    <div class="row mr-0 mb-4">
                        <div class="col-lg-3 language">English</div>
                        <div class="col-lg-9">
                            <div class="row mr-0">
                                <div class="col-lg-7 language-t">
                                    <p>The Central American Parliament</p>
                                </div>
                                <div class="col-lg-5 text-center language-b">
                                    <p>
                                        <button type="button" class="btn btn-outline-primary"><a
                                                href="assets/pdf/English2016-18.pdf">{{ "Download" | uppercase
                                                }}</a></button>
                                    </p>
                                </div>
                            </div>
                            <div class="row mr-0">
                                <div class="col-lg-7 language-t">
                                    <p>The Central American Parliament - PARLACEN</p>
                                </div>
                                <div class="col-lg-5 text-center language-b">
                                    <p>
                                        <button type="button" class="btn btn-outline-primary"><a
                                                href="assets/pdf/English_Presentation_2021-2022.pdf">{{ "Download" |
                                                uppercase }}</a></button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mr-0 mb-4">
                        <div class="col-lg-3 language">Deutsch</div>
                        <div class="col-lg-9">
                            <div class="row mr-0">
                                <div class="col-lg-7 language-t">
                                    <p class="">Das Mittelamerika-Parlament</p>
                                </div>
                                <div class="col-lg-5 text-center language-b">
                                    <p>
                                        <button type="button" class="btn btn-outline-primary"><a
                                                href="assets/pdf/Deutsch2016-18.pdf">{{ "Herunterladen" | uppercase
                                                }}</a></button>
                                    </p>
                                </div>
                            </div>
                            <div class="row mr-0">
                                <div class="col-lg-7 language-t">
                                    <p class="">Das Mittelamerika -Parlament- PARLACEN</p>
                                </div>
                                <div class="col-lg-5 text-center language-b">
                                    <p>
                                        <button type="button" class="btn btn-outline-primary"> <a
                                                href="assets/pdf/Alemán Deutsche_Prasentation_2021-22.pdf">{{ "Herunterladen"
                                                | uppercase }}</a></button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mr-0 mb-4">
                        <div class="col-lg-3 language">Italiano</div>
                        <div class="col-lg-9">
                            <div class="row mr-0">
                                <div class="col-lg-7 language-t">
                                    <p>Il Parlamento Dell'America Centrale</p>
                                </div>
                                <div class="col-lg-5 text-center language-b">
                                    <p>
                                        <button type="button" class="btn btn-outline-primary"><a
                                                href="assets/pdf/Italiano2016-18.pdf">{{ "Scaricare" | uppercase
                                                }}</a></button>
                                    </p>
                                </div>
                            </div>
                            <div class="row mr-0">
                                <div class="col-lg-7 language-t">
                                    <p>Il Parlamento Dell'America Centrale -PARLACEN</p>
                                </div>
                                <div class="col-lg-5 text-center language-b">
                                    <p>
                                        <button type="button" class="btn btn-outline-primary">
                                            <a href="assets/pdf/Presentazione_italiana_2021-2022.pdf">
                                                {{"Scaricare"|uppercase}}
                                            </a>
                                        </button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mr-0 mb-4">
                        <div class="col-lg-3 language">Français</div>
                        <div class="col-lg-9">
                            <div class="row mr-0">
                                <div class="col-lg-7 language-t">
                                    <p>Le Parlement Centramericain</p>
                                </div>
                                <div class="col-lg-5 text-center language-b">
                                    <p>
                                        <button type="button" class="btn btn-outline-primary">
                                            <a href="assets/pdf/Frances2016-18.pdf">
                                                {{"Télécharger"|uppercase}}
                                            </a>
                                        </button>
                                    </p>
                                </div>
                            </div>
                            <div class="row mr-0">
                                <div class="col-lg-7 language-t">
                                    <p>Parlement centraméricain - PARLACEN</p>
                                </div>
                                <div class="col-lg-5 text-center language-b">
                                    <p>
                                        <button type="button" class="btn btn-outline-primary"><a
                                                href="assets/pdf/Presentation_Francaise_2021-2022.pdf">{{
                                                "Télécharger" | uppercase }}</a></button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mr-0 mb-4">
                        <div class="col-lg-3 language">Chino</div>
                        <div class="col-lg-9">
                            <div class="row mr-0">
                                <div class="col-lg-7 language-t">
                                    <p>中美洲議會</p>
                                </div>
                                <div class="col-lg-5 text-center language-b d-none">
                                    <p>
                                        <button type="button" class="btn btn-outline-primary">
                                            <a href="assets/pdf/Presentacion PARLACEN en Chino (Taiwan) 2022.pdf">
                                                {{"去下载"|uppercase}}
                                            </a>
                                        </button>
                                    </p>
                                </div>
                            </div>
                            <div class="row mr-0">
                                <div class="col-lg-7 language-t">
                                    <p>中美洲議會 - PARLACEN</p>
                                </div>
                                <div class="col-lg-5 text-center language-b">
                                    <p>
                                        <button type="button" class="btn btn-outline-primary"><a
                                                href="assets/pdf/Presentacion PARLACEN en Chino (Taiwan) 2022.pdf">{{
                                                "去下载" | uppercase }}</a></button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                
                    <div class="row mr-0">
                        <div class="col-lg-3 language">Arabe</div>
                        <div class="col-lg-9">
                            <div class="row mr-0">
                                <div class="col-lg-7 language-t">
                                    <p>برلمان أمريكا الوسطى</p>
                                </div>
                                <div class="col-lg-5 text-center language-b d-none">
                                    <p>
                                        <button type="button" class="btn btn-outline-primary">
                                            <a href="assets/pdf/Presentacion_en_arabe_2021.pdf">
                                                {{"للتنزيل"|uppercase}}
                                            </a>
                                        </button>
                                    </p>
                                </div>
                            </div>
                            <div class="row mr-0">
                                <div class="col-lg-7 language-t">
                                    <p>PARLACEN - برلمان أمريكا الوسطى</p>
                                </div>
                                <div class="col-lg-5 text-center language-b">
                                    <p>
                                        <button type="button" class="btn btn-outline-primary"><a
                                                href="assets/pdf/Presentacion_en_arabe_2021-22 (1).pdf">{{
                                                "للتنزيل" | uppercase }}</a></button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    Fin comentario traducciones -->
                </div>
            </div>
        </div>
    </div>
</div>