<nav class="navbar navbar-expand-lg navbar-dark">
  <a class="navbar-brand" routerLink="/inicio">
    <img src="assets/img/Logo.png" width="50.64" alt="Parlamento Centroamericano" loading="lazy">
  </a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse justify-content-center" id="navbarSupportedContent">
    <ul class="navbar-nav d-flex">
      <li class="nav-item dropdown active mx-3">
        <a class="nav-link dropdown-toggle mt-2" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false" draggable="false">
          <img class="img-fluid" class="nav-icon" src="assets/svg/AcercaAzul.svg"
            alt="Ícono del Parlamento Centroamericano" width="13" loading="lazy">
          {{'navbar.about' | translate | uppercase}}
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" routerLink="/acerca-de">{{'navbar.about_PARLACEN' | translate}}</a>
          <a class="dropdown-item" routerLink="/historia">{{'navbar.history_of_PARLACEN' | translate}}</a>
          <a class="dropdown-item" routerLink="/marco-politico-juridico">{{'navbar.political_legal_framework' | translate}}</a>
          <a class="dropdown-item" routerLink="/informes-plenaria">{{'navbar.plenary_reports' | translate}}</a>
          <a class="dropdown-item" href="/acerca-de/#languages">{{'navbar.languages' | translate}}</a>
        </div>
      </li>
      <li class="nav-item dropdown active mx-3">
        <a class="nav-link dropdown-toggle mt-2" id="navbarDropdown" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false" draggable="false">
          <img class="img-fluid" src="assets/svg/noun_organization_556214.svg"
            alt="Ícono del Parlamento Centroamericano" width="13" loading="lazy">
          {{ "navbar.organitation" | translate |uppercase }}
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" routerLink="/junta-directiva">
            {{'navbar.board_of_directors' | translate}}
          </a>
          <a class="dropdown-item" routerLink="/diputados">{{'navbar.national_benches' | translate}}</a>
          <a class="dropdown-item" routerLink="/comisiones">{{'navbar.permanent_commissions' | translate}}</a>
          <a class="dropdown-item" routerLink="/grupos-parlamentarios">{{'navbar.parliamentary_groups' | translate}}</a>
          <a class="dropdown-item" routerLink="/asamblea-plenaria">{{'navbar.parliamentary_assembly' | translate}}</a>
          <a class="dropdown-item" routerLink="/bloque-mujeres-parlamentarias">{{'navbar.bloc_of_women_parliamentarians'|translate}}</a>
          <a class="dropdown-item" routerLink="/elecciones">Comisión Electoral 2024</a>
        </div>
      </li>
      <li class="nav-item active mx-3">
        <a class="nav-link mt-2" routerLink="/resoluciones">
          <img class="img-fluid" src="assets/svg/ResolucionesAzul2.svg" alt="Ícono de Buscar" width="18" loading="lazy">
          {{ "navbar.resolutions" | translate | uppercase }}
        </a>
      </li>

      <li class="nav-item active text-center mx-3 brand">
        <img src="assets/img/Logo.png" width="50.64" alt="Logo del Parlamento Centroamericano" loading="lazy"
          routerLink="/inicio">
        <!-- <div class="d-flex nav-item active justify-content-center languages mt-1">
                    <a class="nav-link" class="es" (click)="switchLang('es')">{{ "ES " }}</a>
                    <a class="px-1 text-white"> | </a>
                    <a class="nav-link" class="en" (click)="switchLang('en')">{{ " EN" }}</a>
                </div> -->
      </li>
      <li class="nav-item active mx-3">
        <a class="nav-link mt-2" routerLink="/agenda">
          <img class="img-fluid" class="nav-icon" src="assets/svg/CalendarioAzul.svg" alt="Ícono de Calendario"
            width="10" loading="lazy">
          {{ "navbar.diary" | translate |uppercase }}
        </a>
      </li>
      <li class="nav-item dropdown active mx-3">
        <a class="nav-link dropdown-toggle mt-2" id="navbar-dropdown-multimedia" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <img class="img-fluid" class="nav-icon" src="assets/svg/DerechaAzul.svg" alt="Ícono de Multimedia" width="5"
            loading="lazy">
          {{ "navbar.multimedia"| translate | uppercase }}
        </a>
        <div class="dropdown-menu" aria-labelledby="navbar-dropdown-multimedia">
          <a class="dropdown-item" routerLink="/parlacen-tv">{{ "navbar.PARLACEN_TV" | translate }}</a>
          <!--a class="dropdown-item" routerLink="/boletin-parlamentario">{{"navbar.parliamentary_bulletin" | translate}}</a-->
        </div>
      </li>
      <li class="nav-item dropdown active mx-3">
        <a class="nav-link dropdown-toggle mt-2" id="navbar-dropdown-news" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <img class="img-fluid" class="nav-icon" src="assets/svg/NoticiasAzul.svg" alt="Ícono de Noticias" width="10"
            loading="lazy">
          {{ "navbar.news" |translate |uppercase }}
        </a>
        <div class="dropdown-menu" aria-labelledby="navbar-dropdown-news">
          <a class="dropdown-item" routerLink="/noticias">{{"navbar.present" |translate |uppercase}}</a>
          <a class="dropdown-item" routerLink="/covid-19">{{'Covid-19' | uppercase}}</a>
          <a class="dropdown-item" routerLink="/iota">{{"navbar.iota" |translate |uppercase}}</a>
          <!-- <a class="dropdown-item" routerLink="/iota">{{'Iota' | uppercase}}</a> -->
        </div>
      </li>
      <li class="nav-item active mx-3">
        <a class="nav-link mt-2" routerLink="/buscar">
          <img class="img-fluid" class="nav-icon" src="assets/svg/lupa.svg" alt="Ícono de Calendario" width="15"
            loading="lazy">
          {{ "navbar.search" | translate |uppercase }}
        </a>
      </li>
    </ul>
  </div>
</nav>
