<div class="home">
  <div class="heading">
    <div id="carouselIndicators" class="carousel slide" data-ride="carousel">
      <ol class="carousel-indicators">
        <li data-target="#carouselIndicators" data-slide-to="0" class="active"></li>
        <li data-target="#carouselIndicators" data-slide-to="1"></li>
        <li data-target="#carouselIndicators" data-slide-to="2"></li>
      </ol>
      <div class="carousel-inner">
        <div class="carousel-item active">
            <img src="assets/img/carusel-1.jpeg" class="d-block w-100 carousel-img"
                srcset="assets/img/carusel-1.jpeg 1x, assets/img/carusel-1.jpeg 2x" data-interval="10000"
                alt="JD-2023-2024" loading="lazy">
        </div>
        <div class="carousel-item">
            <img src="assets/img/carusel-2.jpeg" class="d-block w-100 carousel-img"
                srcset="assets/img/carusel-2.jpeg, assets/img/carusel-2.jpeg 2x" data-interval="10000"
                alt="JD-2023-2024" loading="lazy">
        </div>
        <div class="carousel-item">
            <img src="assets/img/carusel-3.jpeg" class="d-block w-100 carousel-img"
                srcset="assets/img/carusel-3.jpeg 1x, assets/img/carusel-3.jpeg 2x"
                data-interval="10000" alt="JD-2023-2024" loading="lazy">
        </div>
    </div>
      <a class="carousel-control-prev" href="#carouselIndicators" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
      </a>
      <a class="carousel-control-next" href="#carouselIndicators" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
      </a>
      <div class="carousel-text d-lg-block">
        <img src="assets/img/Logo.png" alt="Logo del Parlamento Centroamericano" class="pb-4 img" loading="lazy">
        <h3 class="page-title text-white">{{"inicio.carrusel.title"| translate}}</h3>
        <p class="display-text">
          {{"inicio.carrusel.body"| translate}}
        </p>
        <button type="button" class="btn btn-primary btn-leer-mas buttonHome" routerLink="/acerca-de">{{
          "inicio.carrusel.button" | translate| uppercase }}</button>
      </div>
    </div>
  </div>
  <div class="container-lg ">
    <div class="my-container">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item active" aria-current="page">
            <a class="nav-item" role="button">{{ "inicio.nav.inicio"|translate | uppercase}}</a>
          </li>
        </ol>
      </nav>
      <div class="section-title-container pt-5 pb-1">
        <h3 class="section-title">{{ "inicio.novelties.title" |translate| uppercase }}</h3>
      </div>
      <div class="section-body-container">
        <div class="novedades-carousel text-mog2 carousel-desktop">
          <div id="carouselNov" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
              <li data-target="#carouselNov" data-slide-to="0" class="active"></li>
              <li data-target="#carouselNov" data-slide-to="1"></li>
              
            </ol>
            <!-- pantalla desktop concurso Ruben dario-->
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="row text-center">
                  <div class="col-lg-4">
                    <div class="card">
                      <img src="assets/svg/DocumentosAzul.svg" class="card-img-top" alt="..." width="40px"
                        height="40px">
                      <div class="card-body">
                        <h3 class="card-title">
                          {{"inicio.novelties.concurso_dos.title" | translate }}
                        </h3>
                        <p class="cont">
                          {{"inicio.novelties.concurso_dos.text" | translate }}
                        </p>
                        <button type="button" class="btn btn-outline-dark outline-leer-mas">
                          <a class="nav-link" routerLink="/ruben-dario">{{
                            "inicio.next_activities.btn" |translate
                            |uppercase}}</a>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="card">
                      <img src="assets/svg/Resoluciones y  declaracionesAzul.svg" class="card-img-top" alt="..."
                        width="40px" height="40px">
                      <div class="card-body">
                        <h3 class="card-title">
                          {{"inicio.novelties.management_report.title" | translate }} <br>
                          2021-2022
                        </h3>
                        <p class="cont">
                          {{"inicio.novelties.management_report.text" | translate }}
                        </p>
                        <button type="button" class="btn btn-outline-dark outline-leer-mas">
                          <a class="nav-link" href="assets/pdf/Reporte comisiÃ³n de desarrollo Municipal y ParticipaciÃ³n Ciudadana.pdf">{{
                            "inicio.next_activities.btn" |translate |uppercase}}</a>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="card">
                      <img src="assets/svg/AcercaAzul.svg" class="card-img-top" alt="..." width="40px" height="40px">
                      <div class="card-body">
                        <h3 class="card-title">
                          {{"inicio.novelties.advance.title" | translate }}
                        </h3>
                        <p class="cont">
                          {{"inicio.novelties.advance.text" | translate }}
                        </p>
                        <button type="button" class="btn btn-outline-dark outline-leer-mas">
                          <a class="nav-link" href="assets/pdf/PC _ CASOS DE EXITO.pdf"
                            download="Avances-Logrados-Parlacen" target="blank">{{
                            "inicio.next_activities.down" |translate
                            |uppercase}}</a>
                        </button>
                      </div>
                    </div>
                  </div>
                   
                </div>
              </div>
               
              <div class="carousel-item">
                <div class="row text-center">
                  <div class="col-lg-4">
                    <div class="card">
                      <img src="assets/svg/AcercaAzul.svg" class="card-img-top" alt="..." width="40px" height="40px">
                      <div class="card-body">
                        <h3 class="card-title">
                          {{"inicio.novelties.observatory.title" | translate }}
                        </h3>
                        <p class="cont">
                          {{"inicio.novelties.observatory.text" | translate }}
                        </p>
                        <button type="button" class="btn btn-outline-dark outline-leer-mas">
                          <a class="nav-link" href="https://observatorio.parlacen.int/" target="blank">{{
                            "inicio.next_activities.btn" |translate
                            |uppercase}}</a>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="card">
                      <img src="assets/svg/EurolatIcono.svg" class="card-img-top" alt="..." width="40px" height="40px">
                      <div class="card-body">
                        <h3 class="card-title">
                          {{"inicio.novelties.eurolat.title" | translate}}
                        </h3>
                        <p class="info-text">
                          {{"inicio.novelties.eurolat.text" | translate}}
                        </p>
                        <button type="button" class="btn btn-outline-dark outline-leer-mas">
                          <a class="nav-link" routerLink="/eurolat">
                            {{ "inicio.next_activities.btn" |translate |uppercase}}
                          </a>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="card">
                      <img src="assets/svg/CovidAzul.svg" class="card-img-top" alt="..." width="40px" height="40px">
                      <div class="card-body">
                        <h3 class="card-title">
                          {{"inicio.novelties.covid-19.title" | translate}}
                        </h3>
                        <p class="info-text">
                          {{"inicio.novelties.covid-19.text" | translate}}
                        </p>
                        <button type="button" class="btn btn-outline-dark outline-leer-mas">
                          <a class="nav-link" routerLink="/covid-19">
                            {{ "inicio.next_activities.btn" |translate |uppercase}}
                          </a>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
            <a class="carousel-control-prev" href="#carouselNov" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselNov" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
        <div class="novedades-carousel text-mog carousel-movil">
          <div id="carouselNov1" class="carousel slide" data-ride="carousel">
            <ol class="carousel-indicators">
              <li data-target="#carouselNov1" data-slide-to="0" class="active"></li>
              <li data-target="#carouselNov1" data-slide-to="1"></li>
              <li data-target="#carouselNov1" data-slide-to="2"></li>
              <li data-target="#carouselNov1" data-slide-to="3"></li>
              <li data-target="#carouselNov1" data-slide-to="4"></li>
              <li data-target="#carouselNov1" data-slide-to="5"></li>
            </ol>
            <div class="carousel-inner">
              <!-- movil concurso Ruben dario-->
              <div class="carousel-item active">
                <div class="row text-center">
                  <div class="col-lg-4">
                    <div class="card">
                      <img src="assets/svg/DocumentosAzul.svg" class="card-img-top" alt="..." width="40px"
                        height="40px">
                      <div class="card-body">
                        <h3 class="card-title">
                          {{"inicio.novelties.concurso_dos.title" | translate }}
                        </h3>
                        <p class="cont">
                          {{"inicio.novelties.concurso_dos.text" | translate }}
                        </p>
                        <button type="button" class="btn btn-outline-dark outline-leer-mas">
                          <a class="nav-link" routerLink="/ruben-dario">{{
                            "inicio.next_activities.btn" |translate
                            |uppercase}}</a>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- movil Informe de Gestión 2021-2022-->
              <div class="carousel-item">
                <div class="row text-center">
                  <div class="col-lg-4">
                    <div class="card">
                      <img src="assets/svg/Resoluciones y  declaracionesAzul.svg" class="card-img-top" alt="..."
                        width="40px" height="40px">
                      <div class="card-body">
                        <h3 class="card-title">
                          {{"inicio.novelties.management_report.title" | translate }} <br>
                          2021-2022
                        </h3>
                        <p class="cont">
                          {{"inicio.novelties.management_report.text" | translate }}
                        </p>
                        <button type="button" class="btn btn-outline-dark outline-leer-mas">
                          <a class="nav-link" href="assets/pdf/Reporte comisiÃ³n de desarrollo Municipal y ParticipaciÃ³n Ciudadana.pdf">{{
                            "inicio.next_activities.btn" |translate |uppercase}}</a>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- movil Avances Logrados PARLACEN-->
              <div class="carousel-item">
                <div class="row text-center">
                  <div class="col-lg-4">
                    <div class="card">
                      <img src="assets/svg/AcercaAzul.svg" class="card-img-top" alt="..." width="40px" height="40px">
                      <div class="card-body">
                        <h3 class="card-title">
                          {{"inicio.novelties.advance.title" | translate }}
                        </h3>
                        <p class="cont">
                          {{"inicio.novelties.advance.text" | translate }}
                        </p>
                        <button type="button" class="btn btn-outline-dark outline-leer-mas">
                          <a class="nav-link" href="assets/pdf/PC _ CASOS DE EXITO.pdf"
                            download="Avances-Logrados-Parlacen" target="blank">{{
                            "inicio.next_activities.down" |translate
                            |uppercase}}</a>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- movil observatorio del parlacen-->
              <div class="carousel-item">
                <div class="row text-center">
                  <div class="col-lg-4">
                    <div class="card">
                      <img src="assets/svg/AcercaAzul.svg" class="card-img-top" alt="..." width="40px" height="40px">
                      <div class="card-body">
                        <br>
                        <h3 class="card-title">
                          {{"inicio.novelties.observatory.title" | translate }}
                        </h3>
                        <p class="cont">
                          {{"inicio.novelties.observatory.text" | translate }}
                        </p>
                        <button type="button" class="btn btn-outline-dark outline-leer-mas">
                          <a class="nav-link" href="https://observatorio.parlacen.int/" target="blank">{{
                            "inicio.next_activities.btn" |translate
                            |uppercase}}</a>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
               <!-- movil Eurolat-->
               <div class="carousel-item">
                <div class="row text-center">
                  <div class="col-lg-4">
                    <div class="card">
                      <img src="assets/svg/EurolatIcono.svg" class="card-img-top" alt="..." width="40px" height="40px">
                      <div class="card-body">
                        <h3 class="card-title">
                          {{"inicio.novelties.eurolat.title" | translate}}
                        </h3>
                        <p class="info-text">
                          {{"inicio.novelties.eurolat.text" | translate}}
                        </p>
                        <button type="button" class="btn btn-outline-dark outline-leer-mas">
                          <a class="nav-link" routerLink="/eurolat">
                            {{ "inicio.next_activities.btn" |translate |uppercase}}
                          </a>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- movil Covid-19-->
              <div class="carousel-item">
                <div class="row text-center">
                  <div class="col-lg-4">
                    <div class="card">
                      <img src="assets/svg/CovidAzul.svg" class="card-img-top" alt="..." width="40px" height="40px">
                      <div class="card-body">
                        <h3 class="card-title">
                          {{"inicio.novelties.covid-19.title" | translate}}
                        </h3>
                        <p class="info-text">
                          {{"inicio.novelties.covid-19.text" | translate}}
                        </p>
                        <button type="button" class="btn btn-outline-dark outline-leer-mas">
                          <a class="nav-link" routerLink="/covid-19">
                            {{ "inicio.next_activities.btn" |translate |uppercase}}
                          </a>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
                
            </div>


            <a class="carousel-control-prev" href="#carouselNov1" role="button" data-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="sr-only">Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselNov1" role="button" data-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br>
  <br>
  <br>
  <br>
  <div>
    <div class="colorsFE">
      <div class="container-lg">
        <div class="my-container"></div>
      </div>
      <div class="con">
        <div class="novedades ">
          <h3>{{ "inicio.board_of_directors.title" |translate| uppercase }}</h3>
        </div>
        <br>
        <br>
        <div class="row">
          <div class="tex text-mog2">
            <h3 class="presidente-parlacen">
              {{ "inicio.board_of_directors.president_parlament" |translate}}<br />{{
              "inicio.board_of_directors.central_american" |translate}}
            </h3>
            <br />
            <p class="mensaje-presidencia">
              {{ "inicio.board_of_directors.text_1" |translate}}<br />{{
              "inicio.board_of_directors.text_2" |translate}}
            </p>
            <span class="text-sky-blue">{{ "inicio.board_of_directors.position_holder" |translate}}<span
                class="h-d-nadia"> HD RAMÓN EMILIO GORIS TAVERAS</span></span>

          </div>
          <div class="tex text-mog">
            <h3 class="presidente-parlacen">
              {{ "inicio.board_of_directors.president_parlament" |translate}}
              <!-- {{
              "inicio.board_of_directors.central_american" |translate}} -->
            </h3>
            <br />
            <p class="mensaje-presidencia">
              {{ "inicio.board_of_directors.text_1" |translate}}{{ "inicio.board_of_directors.text_2"
              |translate}}
            </p>
            <span class="text-sky-blue">{{ "inicio.board_of_directors.position_holder" |translate}}<span
                class="h-d-nadia"> HD RAMÓN EMILIO GORIS TAVERAS </span></span>

          </div>
          <div class="imag">
            <img src="assets/img/imposicion_Pin_Presidente.jpg" alt="" width="100%" />
          </div>
        </div>
        <div class="row inv">
          <div class="imag">
            <img src="assets/img/Home_JD-sec.jpg" alt="" />
          </div>
          <div class="tex">
            <br />
            <br />
            <h3 class="presidente-parlacen">{{ "inicio.board_of_directors.vice_presidencies" |translate}}
            </h3>
            <br />
            <p class="mensaje-presidencia">
              {{ "inicio.board_of_directors.text_v" |translate}}
            </p>
          </div>
          <br />
          <br />
        </div>

        <div class="row">
          <div class="tex text-mog2">
            <h3 class="presidente-parlacen">{{ "inicio.board_of_directors.secretaries" |translate}}</h3>
            <br />
            <p class="mensaje-presidencia">
              {{ "inicio.board_of_directors.secretaries_txt" |translate}}
              {{ "inicio.board_of_directors.secretaries_txt2" |translate}}
            </p>
          </div>
          <div class="tex text-mog">
            <h3 class="presidente-parlacen">{{ "inicio.board_of_directors.secretaries" |translate}}</h3>
            <br />
            <p class="mensaje-presidencia">
              {{ "inicio.board_of_directors.secretaries_txt" |translate}}
              {{ "inicio.board_of_directors.secretaries_txt2" |translate}}
            </p>
          </div>
          <div class="imag">
            <img src="assets/img/Home_JD.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="my-container">
      <div class="section-title-container pt-5">
        <h3 class="section-title pt-5">
          {{ "inicio.next_activities.title"|translate|uppercase}}
        </h3>
      </div>
      <div class="section-body-container">
        <div class="row conocer-mas-parlacen text-white">
          <div class="col-lg-6 text-center align-self-center ti-text">
            <p class="ti-t">
              {{"inicio.next_activities.txt_1"|translate}}<br />
              {{"inicio.next_activities.txt_2"|translate}}<br />
              {{"inicio.next_activities.txt_3"|translate}}
              
            </p>
            <button type="button" class="btn btn-primary ti-btn" routerLink="/agenda">
              {{ "inicio.plenary_news.btn"|translate}}
            </button>
          </div>
          <div class="col-lg-6 p-0 ti-image">
            <img src="assets/img/Home-3.png" width="100%" class="ti-img" alt="Diputados del Parlamento Centroamericano"
              loading="lazy" srcset="assets/img/Home-3.png 1x, assets/img/Home-3.png 2x">
          </div>
        </div>
      </div>
    </div>
    <div>
      <div class="colorsFE ">
        <div class="container-lg">
          <div class="my-container ">

            <div class="novedades letra">

              <h3>{{ "inicio.plenary_news.title" |translate | uppercase}}</h3>

            </div>

            <div class="section-title-container text-mog2">
              <h3 class="presidente-parlacen">
                {{ "inicio.plenary_news.txt_1" |translate}} <br>{{ "inicio.plenary_news.txt_2" |
                translate}}
              </h3>
            </div>
            <div class="section-title-container text-mog">
              <h3 class="presidente-parlacen" style="text-align: left;">
                {{ "inicio.plenary_news.txt_1" |translate }} {{ "inicio.plenary_news.txt_2" |
                translate}}
              </h3>
            </div>
            <br>
            <div class="row historia-marco-documentos text-center">
              <div class="col-lg-3">
                <div class="card">
                  <div class="card-body">
                    <img src="assets/svg/ResolucionesAzul.svg" class="historia-img align-self-center"
                      alt="Ãcono de Historia" width="34" height="32.23">
                    <h2 class="historia-parlacen-h2">{{ "inicio.plenary_news.resolutions"
                      |translate}}</h2>
                  </div>
                  <div class="card-footer" style="padding-top: 5px;">
                    <p class="historia-parlacen-t" routerLink="/resoluciones">
                      <button type="button" class="btn-leer-dark">
                        <a class="nav-link">{{ "inicio.plenary_news.btn" |translate
                          |uppercase}}</a>
                      </button>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="card">
                  <div class="card-body">
                    <img src="assets/svg/DeclaracionesAzul.svg" class="marco-img align-self-center"
                      alt="Ãcono de Marco JurÃ­dico y PolÃ­tico" width="34">
                    <h2 class="historia-parlacen-h2">{{ "inicio.plenary_news.declarations"
                      |translate}}</h2>
                  </div>
                  <div class="card-footer">
                    <p class="marco-pj-t">
                      <button type="button" class="btn-leer-dark" routerLink="/resoluciones">
                        <a class="nav-link">{{ "inicio.plenary_news.btn" |translate
                          |uppercase}}</a>
                      </button>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="card">
                  <div class="card-body">
                    <img src="assets/svg/PronunciamientosAzul.svg" class="documentos-img align-self-center"
                      alt="Ãcono de Documentos Parlamentaria" width="34">
                    <h2 class="historia-parlacen-h2">{{ "inicio.plenary_news.pronouncements"
                      |translate}}</h2>
                  </div>
                  <div class="card-footer">
                    <p class="docs-plenaria-t">
                      <button type="button" class="btn-leer-dark" routerLink="/resoluciones">
                        <a class="nav-link">{{ "inicio.plenary_news.btn" |translate
                          |uppercase}}</a>
                      </button>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="card">
                  <div class="card-body">
                    <img src="assets/svg/Actas e informesAzul.svg" class="documentos-img align-self-center"
                      alt="Ãcono de Documentos Parlamentaria" width="34">
                    <h2 class="historia-parlacen-h2">{{
                      "inicio.plenary_news.minutes_and_reports"
                      |translate}}</h2>
                  </div>
                  <div class="card-footer">
                    <p class="docs-plenaria-t">
                      <button type="button" class="btn-leer-dark " routerLink="/informes-plenaria">
                        <a class="nav-link">{{ "inicio.plenary_news.btn" |translate}}</a>
                      </button>
                    </p>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        <br>
        <br>
        <br>
        <br><br>
      </div>
    </div>
    <div>
      <div style="background-color: #223149" class="sintoniza">
        <div class="row text-white">
          <div class="col-lg-6 align-self-center exito-text">
            <div class="nov">
              <br>
              <br>
              <h3>{{ "inicio.pelanry_news_tv.title" |translate |uppercase}}</h3>
            </div>
            <br /><br />
            <h3 class="sin text-mog2">{{ "inicio.pelanry_news_tv.txt" |translate }}<br />{{
              "inicio.pelanry_news_tv.txt_1" |translate }}</h3>
            <h3 class="sin text-mog">{{ "inicio.pelanry_news_tv.txt" |translate }} {{
              "inicio.pelanry_news_tv.txt_1" |translate }}</h3>
            <br />
            <p class="mir">
              {{ "inicio.pelanry_news_tv.txt_2" |translate }}<br />
            </p>
            <br />
            <button type="button" class="btn btn-primary prox-actividades-btn" routerLink="/parlacen-tv">
              {{ "inicio.pelanry_news_tv.see_channel" |translate }}
            </button>
          </div>
          <div class="col-lg-6 p-0">
            <img src="assets/img/IMG_0861@2x.png" width="100%" alt="Diputados del Parlamento Centroamericano"
              srcset="assets/img/IMG_0861@2x.png 1x, assets/img/IMG_0861@2x.png 2x">
          </div>
        </div>
      </div>
    </div>
  </div>
  <br class="d-none">
  <br class="d-none">
  <br class="d-none">
  <br>
  <div class="d-none">
    <div class="conten">
      <div class="not">
        <h3>{{ "Ãºltimas about" | uppercase }}</h3>
      </div>
      <br>
      <br>
      <br>
      <div class="row historia-marco-documentos tam text-center">
        <div class="col-lg-4">
          <div class="card" style="background-color: #f5f5f5">
            <div class="card-body">
              <img src="assets/img/national_assembly_south_korea_002_72dpi_1600pxjpg.png"
                class="historia-img align-self-center" alt="Ãcono de Historia" width="100%" />
              <h2 class="historia-parlacen-h">{{ "PublicaciÃ³n 1" }}</h2>
            </div>
            <div class="card-footer p-1">
              <p class="historia-parlacen-t">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam.
                <br />
                <br />
                <br />
                <button type="button" class="btn btn-outline-dark outline-leer-mas">
                  <a class="nav-link">{{ "Leer Noticia" | uppercase }}</a>
                </button>
              </p>
            </div>
            <br />
          </div>
        </div>
        <br />
        <div class="col-lg-4">
          <div class="card" style="background-color: #f5f5f5">
            <div class="card-body">
              <img src="assets/img/serious-young-ethnic-lady-putting-on-medical-mask-on-street-3960919@2x.png"
                class="marco-img align-self-center" alt="Ãcono de Marco JurÃ­dico y PolÃ­tico" width="100%" />
              <h2 class="marco-pj-h">{{ "PublicaciÃ³n 2" }}</h2>
            </div>
            <div class="card-footer p-1">
              <p class="marco-pj-t">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam.
                <br />
                <br />
                <br />
                <button type="button" class="btn btn-outline-dark outline-leer-mas">
                  <a class="nav-link">{{ "Leer Noticia" | uppercase }}</a>
                </button>
              </p>
            </div>
            <br />
          </div>
        </div>
        <br />
        <div class="col-lg-4">
          <div class="card" style="background-color: #f5f5f5">
            <div class="card-body">
              <img src="assets/img/planet-earth-close-up-photo-45208.png" class="documentos-img align-self-center"
                alt="Ãcono de Documentos Parlamentaria" width="100%" />
              <h2 class="docs-plenaria-h">{{ "PublicaciÃ³n 3" }}</h2>
            </div>
            <div class="card-footer p-1">
              <p class="docs-plenaria-t">
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed
                diam nonumy eirmod tempor invidunt ut labore et dolore magna
                aliquyam.
                <br />
                <br />
                <br />
                <button type="button" class="btn btn-outline-dark outline-leer-mas">
                  <a class="nav-link">{{ "Leer Noticia" | uppercase }}</a>
                </button>
              </p>
            </div>
            <br />
          </div>
        </div>

      </div>
    </div>
    <br>
    <br>
    <br>
    <br>
    <br>
  </div>
</div>

<!-- Modal -->
<script>
$( document ).ready(function() {
  $('#myModal').modal('toggle')
});
</script>



<div class="modal fade bd-example-modal-lg" id="myModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"></h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="container-fluid">
          <div class="row">
            
            <div class="col-md-6"> 
              <div class="card">
                <a href="assets/img/temp/Convocatorioa-DirHN.pdf" style="text-align:center;  margin: auto;">
                  <img src="assets/img/temp/Convocatorioa-DirHN.jpg" class="img-fluid" alt="imagen" style="max-width: 100%; ">
                </a>
                
                <div class="card-body">
                  <p class="cont">
                    Contratación SECRETARIA(O) DE COMISIÓN/SEDE
                  </p>
                                    
                </div>
              </div>
            </div>
            <div class="col-md-6"> 
              <div class="card">
                <a href="assets/img/temp/Convocatorioa-SecCom.pdf" style="text-align:center;  margin: auto;">
                  <img src="assets/img/temp/Convocatorioa-SecCom.jpg" class="img-fluid" alt="imagen" style="max-width: 100%; ">
                </a>
                
                <div class="card-body">
                  <p class="cont">
                    Contratación DIRECTOR(A) ADMINISTRATIVO(A) /SUBSEDE DE HONDURAS
                  </p>
                                    
                </div>
              </div>
            </div>
            <!--
            <div class="col-md-6"> 
              <div class="card" >
                <a style="text-align:center;  margin: auto;" >
                  <img src="assets/img/temp/Convocatorioa-DirHN.jpg" class="" alt="imagen" style="max-width: 50%;">
                </a>
                
                <div class="card-body">
                  <p class="cont">
                    “Concurso RUBÉN DARÍO 2024"
                  </p>
                  <button type="button" class="btn btn-outline-dark outline-leer-mas">
                    <a class="nav-link" href="https://parlacen.int/ruben-dario"  target="_blank">{{
                      "inicio.next_activities.btn" |translate
                      |uppercase}}</a>
                  </button>
                </div>
              </div>
            </div>
            -->
          </div>
          
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
      </div>
    </div>
  </div>
</div>
<!-- fin Modal -->