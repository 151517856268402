<div class="junta-directiva">
  <div class="container-lg">
    <div class="my-container">

        <nav aria-label="breadcrumb d-responsive">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">{{ "board_of_directors.breadcum.home" |translate|uppercase}}</li>
                <li class="breadcrumb-item quote-transform">{{ "board_of_directors.breadcum.organitation"
                    |translate|uppercase}}</li>
                <li class="breadcrumb-item quote-transform active" aria-current="page">
                    <a class="nav-item" role="button">ELECCIONES</a>
                </li>
            </ol>
        </nav>
        <!-- comentado  elecciones 2024 -->
        <div class="section-title-container">
            <h3 class="page-title">
                Elecciones a Junta Directiva 2024-2025
            </h3>
        </div>
        
        <div class="accordion" id="accordionExample"> <!-- Seccion Acordeon-->
          <!-- Seccion de documentos-->
          <div class="card">
            <div class="card-header" id="headingOne">
              <h2 class="mb-0">
                <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                  <h2 class="info-title">{{"Circulares y documentos oficiales" |uppercase }}</h2>                </button>
              </h2>
            </div>
        
            <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
              <div class="card-body">
                <div class="row text-center">
                  
                  <div class="col-lg-4">
                    <div class="card hecho">
                      <div class="card-body nobedad-b">
                        <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                          alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                        <h2 class="group-title">
                          Circular 01 Convocatoria a Elecciones Octuber 2024
                        </h2>
                      </div>
                      <div class="card-footer">
                        <p class="hecho-f">
                          <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                            <a class="nav-link" target="_blank" href="assets/pdf/eleccion25/Circular 01-2024_Informeoficialpostulación_JD2024-2025.pdf">
                                {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                                translate | uppercase }}
                            </a>
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="card hecho">
                      <div class="card-body nobedad-b">
                        <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                          alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                        <h2 class="group-title">
                          Circular 02-2024 Fecha limite de potulaciones y solicitud de fotografía
                        </h2>
                      </div>
                      <div class="card-footer">
                        <p class="hecho-f">
                          <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                            <a class="nav-link" target="_blank" href="assets/pdf/eleccion25/Circular 02-2024 Fecha limite de potulaciones y solicitud de fotografía.pdf">
                                {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                                translate | uppercase }}
                            </a>
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>
  
                  <div class="col-lg-4">
                    <div class="card hecho">
                      <div class="card-body nobedad-b">
                        <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                          alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                        <h2 class="group-title">
                          Circular 03-2024 Remisión Calendario Electoral
                        </h2>
                      </div>
                      <div class="card-footer">
                        <p class="hecho-f">
                          <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                            <a class="nav-link" target="_blank" href="assets/pdf/eleccion25/Circular 03-2024 Remisión Calendario Electoral.pdf">
                                {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                                translate | uppercase }}
                            </a>
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>
                  
                  <div class="col-lg-4">
                    <div class="card hecho">
                      <div class="card-body nobedad-b">
                        <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                          alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                        <h2 class="group-title">
                          Circular 04-2024 solicitud certificación electoral
                        </h2>
                      </div>
                      <div class="card-footer">
                        <p class="hecho-f">
                          <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                            <a class="nav-link" target="_blank" href="assets/pdf/eleccion25/Circular 04-2024 solicitud certificación electoral.pdf">
                                {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                                translate | uppercase }}
                            </a>
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>
  
                  <div class="col-lg-4">
                    <div class="card hecho">
                      <div class="card-body nobedad-b">
                        <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                          alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                        <h2 class="group-title">
                          Circular 05-2024 Remisión Reglamento Electoral 2024
                        </h2>
                      </div>
                      <div class="card-footer">
                        <p class="hecho-f">
                          <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                            <a class="nav-link" target="_blank" href="assets/pdf/eleccion25/Circular 05-2024 Remisión Reglamento Electoral 2024.pdf">
                                {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                                translate | uppercase }}
                            </a>
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>
  
                  <div class="col-lg-4">
                    <div class="card hecho">
                      <div class="card-body nobedad-b">
                        <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                          alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                        <h2 class="group-title">
                          Circular 07-2024 Convocatoria ensayos de votaciones
                        </h2>
                      </div>
                      <div class="card-footer">
                        <p class="hecho-f">
                          <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                            <a class="nav-link" target="_blank" href="assets/pdf/eleccion25/Circular 07-2024 Convocatoria ensayos de votaciones.pdf">
                                {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                                translate | uppercase }}
                            </a>
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>
  
                  <div class="col-lg-4">
                    <div class="card hecho">
                      <div class="card-body nobedad-b">
                        <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                          alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                        <h2 class="group-title">
                          Circular 08-2024 Publicación Padrón Electoral 2024
                        </h2>
                      </div>
                      <div class="card-footer">
                        <p class="hecho-f">
                          <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                            <a class="nav-link" target="_blank" href="assets/pdf/eleccion25/Circular 08-2024 Publicación Padrón Electoral 2024.pdf">
                                {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                                translate | uppercase }}
                            </a>
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>
  
                  <div class="col-lg-4">
                    <div class="card hecho">
                      <div class="card-body nobedad-b">
                        <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                          alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                        <h2 class="group-title">
                          Circular 09-2024 Remisión Normativa Ética Electoral
                        </h2>
                      </div>
                      <div class="card-footer">
                        <p class="hecho-f">
                          <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                            <a class="nav-link" target="_blank" href="assets/pdf/eleccion25/Circular 09-2024 Remisión Normativa Ética Electoral.pdf">
                                {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                                translate | uppercase }}
                            </a>
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>
  
                  <div class="col-lg-4">
                    <div class="card hecho">
                      <div class="card-body nobedad-b">
                        <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                          alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                        <h2 class="group-title">
                          Circular 10-2024 Cierre de campaña
                        </h2>
                      </div>
                      <div class="card-footer">
                        <p class="hecho-f">
                          <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                            <a class="nav-link" target="_blank" href="assets/pdf/eleccion25/Circular 10-2024 Cierre de campaña.pdf">
                                {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                                translate | uppercase }}
                            </a>
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>
  
                  <div class="col-lg-4">
                    <div class="card hecho">
                      <div class="card-body nobedad-b">
                        <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                          alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                        <h2 class="group-title">
                          Circular 11-2024 Remisión instructivo de votación
                        </h2>
                      </div>
                      <div class="card-footer">
                        <p class="hecho-f">
                          <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                            <a class="nav-link" target="_blank" href="assets/pdf/eleccion25/Circular 11-2024 Remisión instructivo de votación.pdf">
                                {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                                translate | uppercase }}
                            </a>
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>
  
                  <div class="col-lg-4">
                    <div class="card hecho">
                      <div class="card-body nobedad-b">
                        <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                          alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                        <h2 class="group-title">
                          Circular 12-2024 Cierre de Inscripción y Registro de Postulaciones
                        </h2>
                      </div>
                      <div class="card-footer">
                        <p class="hecho-f">
                          <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                            <a class="nav-link" target="_blank" href="assets/pdf/eleccion25/Circular 12-2024 Cierre de Inscripción y Registro de Postulaciones.pdf">
                                {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                                translate | uppercase }}
                            </a>
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>
  
                  <div class="col-lg-4">
                    <div class="card hecho">
                      <div class="card-body nobedad-b">
                        <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                          alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                        <h2 class="group-title">
                          Circular 13-2024 Publicidad Campaña Electoral 2024.pdf
                        </h2>
                      </div>
                      <div class="card-footer">
                        <p class="hecho-f">
                          <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                            <a class="nav-link" target="_blank" href="assets/pdf/eleccion25/Circular 13-2024 Publicidad Campaña Electoral 2024.pdf">
                                {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                                translate | uppercase }}
                            </a>
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>
  
                  <div class="col-lg-4">
                    <div class="card hecho">
                      <div class="card-body nobedad-b">
                        <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                          alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                        <h2 class="group-title">
                          CALENDARIO COMISIÓN ELECTORAL 2024
                        </h2>
                      </div>
                      <div class="card-footer">
                        <p class="hecho-f">
                          <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                            <a class="nav-link" target="_blank" href="assets/pdf/eleccion25/CALENDARIO COMISIÓN ELECTORAL-2024.pdf">
                                {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                                translate | uppercase }}
                            </a>
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>
  
                  <div class="col-lg-4">
                    <div class="card hecho">
                      <div class="card-body nobedad-b">
                        <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                          alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                        <h2 class="group-title">
                          Cronograma 27 Octuber 2024
                        </h2>
                      </div>
                      <div class="card-footer">
                        <p class="hecho-f">
                          <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                            <a class="nav-link" target="_blank" href="assets/pdf/eleccion25/Cronograma 27OCT 2024.pdf">
                                {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                                translate | uppercase }}
                            </a>
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>
  
                  <div class="col-lg-4">
                    <div class="card hecho">
                      <div class="card-body nobedad-b">
                        <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                          alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                        <h2 class="group-title">
                          REGLAMENTO DEL PROCESO ELECTORAL REVISADO 01 OCTUBRE 2024 APROBADO
                        </h2>
                      </div>
                      <div class="card-footer">
                        <p class="hecho-f">
                          <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                            <a class="nav-link" target="_blank" href="assets/pdf/eleccion25/REGLAMENTO DEL PROCESO ELECTORAL REVISADO 01OCTUBRE2024 APROBADO.pdf">
                                {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                                translate | uppercase }}
                            </a>
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>
  
                  <div class="col-lg-4">
                    <div class="card hecho">
                      <div class="card-body nobedad-b">
                        <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                          alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                        <h2 class="group-title">
                          RESOLUCIÓN No. CE-01-2024 CALENDARIO COMISIÓN ELECTORAL 2024.pdf
                        </h2>
                      </div>
                      <div class="card-footer">
                        <p class="hecho-f">
                          <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                            <a class="nav-link" target="_blank" href="assets/pdf/eleccion25/RESOLUCIÓN No. CE-01-2024 CALENDARIO COMISIÓN ELECTORAL 2024.pdf">
                                {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                                translate | uppercase }}
                            </a>
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>
  
                  <div class="col-lg-4">
                    <div class="card hecho">
                      <div class="card-body nobedad-b">
                        <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                          alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                        <h2 class="group-title">
                          RESOLUCIÓN CE No. 02-2024 PADRON ELECTORAL 2024 APROBADO
                        </h2>
                      </div>
                      <div class="card-footer">
                        <p class="hecho-f">
                          <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                            <a class="nav-link" target="_blank" href="assets/pdf/eleccion25/RESOLUCIÓN CE No. 02-2024 PADRON ELECTORAL 2024 APROBADO.pdf">
                                {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                                translate | uppercase }}
                            </a>
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>
  
                  <div class="col-lg-4">
                    <div class="card hecho">
                      <div class="card-body nobedad-b">
                        <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                          alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                        <h2 class="group-title">
                          RESOLUCIÓN CE No 03-2024  CAMPAÑA ELECTORAL 2024 APROBADO
                        </h2>
                      </div>
                      <div class="card-footer">
                        <p class="hecho-f">
                          <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                            <a class="nav-link" target="_blank" href="assets/pdf/eleccion25/RESOLUCIÓN CE No 03-2024  CAMPAÑA ELECTORAL 2024 APROBADO.pdf">
                                {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                                translate | uppercase }}
                            </a>
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>
                  
                  <div class="col-lg-4">
                    <div class="card hecho">
                      <div class="card-body nobedad-b">
                        <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                          alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                        <h2 class="group-title">
                          INSTRUCTIVO DE VOTACION CE 2024
                        </h2>
                      </div>
                      <div class="card-footer">
                        <p class="hecho-f">
                          <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                            <a class="nav-link" target="_blank" href="assets/pdf/eleccion25/INSTRUCTIVO DE VOTACION CE 2024.pdf">
                                {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                                translate | uppercase }}
                            </a>
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>
  
                  <div class="col-lg-4">
                    <div class="card hecho">
                      <div class="card-body nobedad-b">
                        <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                          alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                        <h2 class="group-title">
                          Normativa de Ética Electoral
                        </h2>
                      </div>
                      <div class="card-footer">
                        <p class="hecho-f">
                          <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                            <a class="nav-link" target="_blank" href="assets/pdf/eleccion25/Normativa de Ética Electoral.pdf">
                                {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                                translate | uppercase }}
                            </a>
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>
                  
                </div>
                
              </div>
            </div>
          </div> <!-- FIN Seccion de documentos -->
                    
          <!-- ------------------------------------------------- Seccion Candidatos ------------------------------------------------- -->
          <!-- Seccion de Candidatos El Salvador-->
          <div class="card">
            <div class="card-header" id="headingTwo">
              <h2 class="mb-0">
                <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  <h2>Candidatos El Salvador</h2>  
                </button>
              </h2>
            </div>
            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
              <div class="card-body">
                  <div class="row text-center">
                    <div class="col-lg-12">
                      <h2 class="group-title">
                        Candidata-Candidato a Presidencia
                      </h2>
                    </div>
                    <div class="col-lg-6">
                      <div class="card-body nobedad-b">
                        <img width="100%" height="100%" src="assets/pdf/eleccion25/SV-pre-CARLOS-EL-SALVADOR-JD-24-25.png" >
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <h2 class="group-title">
                        Candidata-Candidato a Secretaría
                      </h2>
                    </div>
                    <div class="col-lg-6">
                    <div class="card-body nobedad-b">
                      <img width="100%" height="100%" src="assets/pdf/eleccion25/SV-sec-CECI-EL-SALVADOR-JD-24-25.png" >
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>  <!-- FIN Seccion de Candidatos El Salvador-->
          <!-- Seccion de Candidatos Guatemala-->
          <div class="card">
            <div class="card-header" id="headingThree">
              <h2 class="mb-0">
                <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  <h2>Candidatos Guatemala</h2>
                </button>
              </h2>
            </div>
            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
              <div class="card-body">
                <div class="row text-center">
                  <div class="col-lg-12">
                    <h2 class="group-title">
                      Candidata-Candidato a Vice Presidencia
                    </h2>
                  </div>
                  <div class="col-lg-6">
                    <div class="card-body nobedad-b">
                      <img width="100%" height="100%" src="assets/pdf/eleccion25/gt-vice-ANOTNIO-GUATE-JD-24-25.png" >
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <h2 class="group-title">
                      Candidata-Candidato a Secretaría
                    </h2>
                  </div>
                  <div class="col-lg-6">
                    <div class="card-body nobedad-b">
                      <img width="100%" height="100%" src="assets/pdf/eleccion25/gt-sec-EDGAR-GUATE-JD-24-25.png" >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div><!-- FINSeccion de Candidatos Guatemala-->
          <!-- Seccion de Candidatos Honduras -->
          <div class="card">
            <div class="card-header" id="headingHD">
              <h2 class="mb-0">
                <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseHD" aria-expanded="false" aria-controls="collapseHD">
                  <h2>Candidatos Honduras</h2>
                </button>
              </h2>
            </div>
            <div id="collapseHD" class="collapse" aria-labelledby="headingHD" data-parent="#accordionExample">

              <div class="card-body">
                  <div class="row text-center">
                    <div class="col-lg-12">
                      <h2 class="group-title">
                        Candidata-Candidato a Vice Presidencia
                      </h2>
                    </div>
                    <div class="col-lg-6">
                      <div class="card-body nobedad-b">
                        <img width="100%" height="100%" src="assets/pdf/eleccion25/HD-vice-NEDIS-HONDURAS-JD-24-25.png" >
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="card-body nobedad-b">
                        <img width="100%" height="100%" src="assets/pdf/eleccion25/HD-vice-joseAguilar-HONDURAS-JD-24-25.png" >
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <h2 class="group-title">
                        Candidata-Candidato a Secretaría
                      </h2>
                    </div>
                    <div class="col-lg-6">
                    <div class="card-body nobedad-b">
                      <img width="100%" height="100%" src="assets/pdf/eleccion25/HD-sec-JORGE-HONDURAS-JD-24-25.png" >
                    </div>
                    
                  </div>
                </div>
              </div>
              
            </div>
            
          </div>  <!-- FIN Seccion de Candidatos Honduras -->
          <!-- Seccion de Candidatos Ni-->
          <div class="card">
            <div class="card-header" id="headingNi">
              <h2 class="mb-0">
                <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseNi" aria-expanded="false" aria-controls="collapseNi">
                  <h2>Candidatos Nicaragua</h2>
                </button>
              </h2>
            </div>
            <div id="collapseNi" class="collapse" aria-labelledby="headingNi" data-parent="#accordionExample">
              <div class="card-body">
                  <div class="row text-center">
                    <div class="col-lg-12">
                      <h2 class="group-title">
                        Candidata-Candidato a Vice Presidencia
                      </h2>
                    </div>
                    <div class="col-lg-6">
                      <div class="card-body nobedad-b">
                        
                        <img width="100%" height="100%" src="assets/pdf/eleccion25/ni-vice-GILLE-NICA-JD-24-25.png" >
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <h2 class="group-title">
                        Candidata-Candidato a Secretaría
                      </h2>
                    </div>
                    <div class="col-lg-6">
                    <div class="card-body nobedad-b">
                      
                      <img width="100%" height="100%" src="assets/pdf/eleccion25/ni-sec-AIDA-NICA-JD-24-25.png" >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  <!-- FIN Seccion de Candidatos Ni-->
          <!-- Seccion de Candidatos Pa-->
          <div class="card">
            <div class="card-header" id="headingPa">
              <h2 class="mb-0">
                <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapsePa" aria-expanded="false" aria-controls="collapsePa">
                  <h2>Candidatos Panamá</h2>
                </button>
              </h2>
            </div>
            <div id="collapsePa" class="collapse" aria-labelledby="headingPa" data-parent="#accordionExample">
              <div class="card-body">
                  <div class="row text-center">
                    <div class="col-lg-12">
                      <h2 class="group-title">
                        Candidata-Candidato a Vice Presidencia
                      </h2>
                    </div>
                    <div class="col-lg-6">
                      <div class="card-body nobedad-b">
                        
                        <img width="100%" height="100%" src="assets/pdf/eleccion25/pa-vice-pa-DORINGO-DC-PANAMÁ-JD-24-25.png" >
                      </div>
                    </div>
                    
                    <div class="col-lg-6">
                      <div class="card-body nobedad-b">
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <h2 class="group-title">
                        Candidata-Candidato a Secretaría
                      </h2>
                    </div>
                    <div class="col-lg-6">
                      <div class="card-body nobedad-b">
                        <img width="100%" height="100%" src="assets/pdf/eleccion25/pa-sec-LESLY-PANAMA-JD-24-25V.png" >
                      </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="card-body nobedad-b">

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  <!--Fin  Seccion de Candidatos Pa-->
          <!-- Seccion de Candidatos Republica Dominicana-->
          <div class="card">
            <div class="card-header" id="headingRep">
              <h2 class="mb-0">
                <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseRep" aria-expanded="false" aria-controls="collapseRep">
                  <h2>Candidatos República Dominicana</h2>
                </button>
              </h2>
            </div>
            <!-- Seccion de Candidatos Republica Dominicana -->
            <div id="collapseRep" class="collapse" aria-labelledby="headingRep" data-parent="#accordionExample">
              <div class="card-body">
                <div class="row text-center">
                  <div class="col-lg-12">
                    <h2 class="group-title">
                      Candidata-Candidato a Vice Presidencia
                    </h2>
                  </div>
                  <div class="col-lg-6">
                    <div class="card-body nobedad-b">
                      <img width="100%" height="100%" src="assets/pdf/eleccion25/rd-vice-RAMON-RD-JD-24-25.png" >
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="card-body nobedad-b">
                      
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <h2 class="group-title">
                      Candidata-Candidato a Secretaría
                    </h2>
                  </div>
                  <div class="col-lg-6">
                    <div class="card-body nobedad-b">
                      <img width="100%" height="100%" src="assets/pdf/eleccion25/rd-sec-EDDY-RD-JD-24-25.png" >
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="card-body nobedad-b">
                  
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  <!-- FIN Seccion de Candidatos Republica Dominicana-->

        </div>  <!-- FIN  Seccion Acordeon-->

  </div>
</div>