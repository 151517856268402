<div class="junta-directiva">
    <div class="container-lg">
        <div class="my-container">
            <nav aria-label="breadcrumb d-responsive">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">{{ "board_of_directors.breadcum.home" |translate|uppercase}}</li>
                    <li class="breadcrumb-item quote-transform">{{ "board_of_directors.breadcum.organitation"
                        |translate|uppercase}}</li>
                    <li class="breadcrumb-item quote-transform active" aria-current="page">
                        <a class="nav-item" role="button">{{ "board_of_directors.breadcum.board_of_directors"
                            |translate|uppercase}}</a>
                    </li>
                </ol>
            </nav>
            <div class="section-title-container">
                <h3 class="page-title">
                    {{ "board_of_directors.title" |translate}}
                </h3>
            </div>
            <div class="section-title-container pt-5 pb-1">
                <h3 class="section-title d-none">
                    {{ "board_of_directors.body.text" |translate |uppercase}}
                </h3>
            </div>
            <img src="../../../assets/img/Junta_Directiva_2023-2024_enero.jpg" width="100%" alt="Junta directiva parlacen 2024">
            <div class="cargos-mayores d-none">
                <div class="row pt-5 pb-3">
                    <div class="col-md-4 p-0 m-0"></div>
                    <div class="col-md-4">
                        <div class="card mb-3">
                            <img src="assets/img/_H.D.H.Fanny_Carolina_Salinas_Fernandez.jpg" class="card-img-top"
                                srcset="assets/img/_H.D.H.Fanny_Carolina_Salinas_Fernandez.jpg 1x, assets/img/_H.D.H.Fanny_Carolina_Salinas_Fernandez.jpg 2x"
                                alt="Perfil Parlamentario">
                            <div class="card-body light-container">
                                <h2 class="info-title">{{ "board_of_directors.body.president" |translate }}</h2>
                                <p class="card-text info-text">H.D Fanny Carolina Salinas Fernández</p>
                                <p class="enhanced-text">{{"board_of_directors.body.country" |translate }}: Honduras</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 p-0 m-0"></div>
                </div>
                <div class="row pt-3 pb-3">
                    <div class="col-md-4">
                        <div class="card">
                            <img src="assets/img/HD.ELS.ANTONIO_ECHEVERRIA_VELIZ.jpg" class="card-img-top"
                                alt="Perfil Parlamentaria"
                                srcset="assets/img/HD.ELS.ANTONIO_ECHEVERRIA_VELIZ.jpg 1x, assets/img/HD.ELS.ANTONIO_ECHEVERRIA_VELIZ.jpg 2x">
                            <div class="card-body light-container">
                                <h2 class="info-title">{{"board_of_directors.body.vicepresident" |translate }}</h2>
                                <p class="card-text info-text">H.D Antonio Echeverría Véliz</p>
                                <p class="enhanced-text">{{"board_of_directors.body.country" |translate }}: EL SALVADOR
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-4">
                        <div class="card">
                            <img src="assets/img/Junta-Directiva-2019-2020-SecretarioGT-Parlacen.png"
                                class="card-img-top" alt="Perfil Parlamentario"
                                srcset="assets/img/Junta-Directiva-2019-2020-SecretarioGT-Parlacen.png 1x, assets/img/Junta-Directiva-2019-2020-SecretarioGT-Parlacen.png 2x">
                            <div class="card-body light-container">
                                <h2 class="info-title">{{"board_of_directors.body.vicepresident" |translate }}</h2>
                                <p class="card-text info-text">H.D Carlos Rafael Fion Morales</p>
                                <p class="enhanced-text">{{"board_of_directors.body.country" |translate }}: GUATEMALA
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <img src="assets/img/_H.D.NI.Aleyda de la Cruz Trujillo Ruiz.jpg" class="card-img-top"
                                alt="Perfil Parlamentaria"
                                srcset="assets/img/_H.D.NI.Aleyda%20de%20la%20Cruz%20Trujillo%20Ruiz.jpg 1x, assets/img/_H.D.NI.Aleyda%20de%20la%20Cruz%20Trujillo%20Ruiz.jpg 2x">
                            <div class="card-body light-container">
                                <h2 class="info-title">{{"board_of_directors.body.vicepresident" |translate }}</h2>
                                <p class="card-text info-text">H.D Aleyda de la Cruz Trujillo Ruiz</p>
                                <p class="enhanced-text">{{"board_of_directors.body.country" |translate }}: NICARAGUA
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row pt-3 pb-3">
                    <div class="col-md-4">
                        <div class="card">
                            <img src="assets/img/H.D.PN. Amado Cerrud Acevedo.jpg" class="card-img-top"
                                alt="Perfil Parlamentaria"
                                srcset="assets/img/H.D.PN.%20Amado%20Cerrud%20Acevedo.jpg 1x, assets/img/H.D.PN.%20Amado%20Cerrud%20Acevedo.jpg 2x">
                            <div class="card-body light-container">
                                <h2 class="info-title">{{"board_of_directors.body.vicepresident" |translate }}</h2>
                                <p class="card-text info-text">H.D Amado Cerrud Acevedo</p>
                                <p class="enhanced-text">{{"board_of_directors.body.country" |translate }}: PANAMÁ</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <img src="assets/img/Junta-Directiva-2019-2020-VPRepDom-Parlacen.png" class="card-img-top"
                                alt="Perfil Parlamentaria"
                                srcset="assets/img/Junta-Directiva-2019-2020-VPRepDom-Parlacen.png 1x, assets/img/Junta-Directiva-2019-2020-VPRepDom-Parlacen.png 2x">
                            <div class="card-body light-container">
                                <h2 class="info-title">{{"board_of_directors.body.vicepresident" |translate }}</h2>
                                <p class="card-text info-text">H.D Ramón Emilio Gori Taveras</p>
                                <p class="enhanced-text">{{"board_of_directors.body.country" |translate }}: REPÚBLICA
                                    DOMINICANA</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row pt-3 pb-5">
                    <div class="col-md-4">
                        <div class="card mb-3">
                            <img src="assets/img/DIPHERMESFOLORES.jpg" class="card-img-top" alt="Perfil Parlamentario"
                                srcset=" assets/img/DIPHERMESFOLORES.jpg 1x">
                            <div class="card-body light-container">
                                <h2 class="info-title">{{ "board_of_directors.body.Secretary" |translate }}</h2>
                                <p class="card-text info-text"> H.D Hermes Alcides Flores Molina</p>
                                <p class="enhanced-text">{{"board_of_directors.body.country" |translate }}: EL SALVADOR
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card mb-3">
                            <img src="assets/img/HD.GT.MarioRobertoValdeavellanoHernandez.jpg" class="card-img-top"
                                alt="Perfil Parlamentario"
                                srcset="assets/img/HD.GT.MarioRobertoValdeavellanoHernandez.jpg 1x, assets/img/HD.GT.MarioRobertoValdeavellanoHernandez.jpg 2x">
                            <div class="card-body light-container">
                                <h2 class="info-title">{{"board_of_directors.body.Secretary" |translate }}</h2>
                                <p class="card-text info-text">H.D Mario Roberto Valdeavellano Hernández</p>
                                <p class="enhanced-text">{{"board_of_directors.body.country" |translate }}: GUATEMALA
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card mb-3">
                            <img src="assets/img/HD.HD.JaimeArturoNativiGalvez.jpg" class="card-img-top"
                                alt="Perfil Parlamentario"
                                srcset="assets/img/HD.HD.JaimeArturoNativiGalvez.jpg 1x, assets/img/HD.HD.JaimeArturoNativiGalvez.jpg 2x">
                            <div class="card-body light-container">
                                <h2 class="info-title">{{"board_of_directors.body.Secretary" |translate }}</h2>
                                <p class="card-text info-text">H.D Jaime Arturo Nativí Gálvez</p>
                                <p class="enhanced-text">{{"board_of_directors.body.country" |translate }}: HONDURAS</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row pt-3 pb-5">
                    <div class="col-md-4">
                        <div class="card mb-3">
                            <img src="assets/img/HD.NI.AdolfoJoseMartinezCole.jpg" class="card-img-top"
                                alt="Perfil Parlamentario"
                                srcset="assets/img/HD.NI.AdolfoJoseMartinezCole.jpg 1x, assets/img/HD.NI.AdolfoJoseMartinezCole.jpg 2x">
                            <div class="card-body light-container">
                                <h2 class="info-title">{{"board_of_directors.body.Secretary" |translate }}</h2>
                                <p class="card-text info-text"> H.D Adolfo José Martínez Cole</p>
                                <p class="enhanced-text">{{"board_of_directors.body.country" |translate }}: NICARAGUA
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card mb-3">
                            <img src="assets/img/HD.PN.JoseNarcisoRamosReyes.jpg" class="card-img-top"
                                alt="Perfil Parlamentario"
                                srcset="assets/img/HD.PN.JoseNarcisoRamosReyes.jpg 1x, assets/img/HD.PN.JoseNarcisoRamosReyes.jpg 2x">
                            <div class="card-body light-container">
                                <h2 class="info-title">{{"board_of_directors.body.Secretary" |translate }}</h2>
                                <p class="card-text info-text">H.D José Narciso Ramos Reyes</p>
                                <p class="enhanced-text">{{"board_of_directors.body.country" |translate }}: PANAMÁ</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card mb-3">
                            <img src="assets/img/Junta-Directiva-2019-2020-SecreRepDom-Parlacen.png"
                                class="card-img-top" alt="Perfil Parlamentario"
                                srcset="assets/img/Junta-Directiva-2019-2020-SecreRepDom-Parlacen.png 1x, assets/img/Junta-Directiva-2019-2020-SecreRepDom-Parlacen.png 2x">
                            <div class="card-body light-container">
                                <h2 class="info-title">{{"board_of_directors.body.Secretary" |translate }}</h2>
                                <p class="card-text info-text">H.D Franklin Rafael De la Cruz Ramos</p>
                                <p class="enhanced-text">{{"board_of_directors.body.country" |translate }}: REPÚBLICA
                                    DOMINICANA</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="light-container">
        <div class="container-lg">
            <div class="my-container">
                <div class="section-title-container cuadro-admin-pasadas">
                    <h3 class="section-title">{{"board_of_directors.news_from_the_plenary.title" |translate |uppercase}}
                    </h3>
                </div>
                <div class="section-body-container">
                    <div class="trabajo-parlacen">
                        <h2 class="info-title">{{"board_of_directors.news_from_the_plenary.title2" |translate }}</h2>
                        <div class="row text-center">
                            <div class="col-lg-4">
                                <div class="card hecho">
                                    <div class="card-body hecho-b">
                                        <img src="assets/svg/Resoluciones y  declaracionesAzul.svg"
                                            class="hecho-img align-self-center" alt="Ícono de Historia" width="34"
                                            height="65" loading="lazy">
                                        <h2 class="group-title">{{"board_of_directors.news_from_the_plenary.resolution"
                                            |translate }}</h2>
                                    </div>
                                    <div class="card-footer">
                                        <p class="hecho-f">
                                            <button type="button" class="btn btn-outline-dark outline-ver-aqui"
                                                routerLink="/resoluciones">
                                                <a class="nav-link">{{"board_of_directors.news_from_the_plenary.btn"
                                                    |translate |uppercase}}</a>
                                            </button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="card hecho">
                                    <div class="card-body nobedad-b">
                                        <img src="assets/svg/PronunciamientosAzul.svg"
                                            class="hecho-img align-self-center" alt="Ícono de Marco Jurídico y Político"
                                            width="28" height="65" loading="lazy">
                                        <h2 class="group-title">{{"board_of_directors.news_from_the_plenary.minutes"
                                            |translate }}</h2>
                                    </div>
                                    <div class="card-footer">
                                        <p class="hecho-f">
                                            <button type="button" class="btn btn-outline-dark outline-ver-aqui"
                                                routerLink="/informes-plenaria">
                                                <a class="nav-link">{{"board_of_directors.news_from_the_plenary.btn"
                                                    |translate |uppercase}}</a>
                                            </button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <div class="card hecho">
                                    <div class="card-body">
                                        <img src="assets/svg/Actas e informesAzul.svg"
                                            class="hecho-img align-self-center" alt="Ícono de Documentos Parlamentaria"
                                            width="28" height="65" loading="lazy">
                                        <h2 class="group-title">
                                            {{"board_of_directors.news_from_the_plenary.parliament_reports" |translate
                                            }}</h2>
                                    </div>
                                    <div class="card-footer">
                                        <p class="hecho-c">
                                            <button type="button" class="btn btn-outline-dark outline-ver-aqui"
                                                routerLink="/informes-plenaria">
                                                <a class="nav-link">{{"board_of_directors.news_from_the_plenary.btn"
                                                    |translate |uppercase}}</a>
                                            </button>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-lg">
        <div class="my-container">
            <div class="section-body-container">
                <div class="mt-5 pt-5">
                    <h3 class="section-title">{{"board_of_directors.know_more.title" |translate |uppercase}}</h3>
                </div>
                <div class="row conocer-mas text-white">
                    <div class="col-lg-6 text-center align-self-center ti-text">
                        <p class="ti-t">{{"board_of_directors.know_more.text" |translate }}</p>
                        <button type="button" class="btn btn-primary ti-btn"
                            routerLink='/acerca-de'>{{"board_of_directors.news_from_the_plenary.btn" |translate
                            |uppercase}}</button>
                    </div>
                    <div class="col-lg-6 p-0 ti-image">
                        <img src="assets/img/Organizacion--Junta-directiva.png" width="100%" class="ti-img"
                            alt="Diputados del Parlamento Centroamericano" loading="lazy"
                            srcset="assets/img/Organizacion--Junta-directiva.png 1x, assets/img/Organizacion--Junta-directiva.png 2x">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
