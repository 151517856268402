<div class="junta-directiva">
  <div class="container-lg">
    <div class="my-container">

        <nav aria-label="breadcrumb d-responsive">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">{{ "board_of_directors.breadcum.home" |translate|uppercase}}</li>
                <li class="breadcrumb-item quote-transform">{{ "board_of_directors.breadcum.organitation"
                    |translate|uppercase}}</li>
                <li class="breadcrumb-item quote-transform active" aria-current="page">
                    <a class="nav-item" role="button">ELECCIONES</a>
                </li>
            </ol>
        </nav>
        <!-- comentado  elecciones 2023 
        <div class="section-title-container">
            <h3 class="page-title">
                Elecciones 2023
            </h3>
        </div>
        <!- - Seccion de Candidatos Rep - ->
        <div class="accordion" id="accordionExample">
          <!- - Seccion de documentos- ->
          <div class="card">
            <div class="card-header" id="headingOne">
              <h2 class="mb-0">
                <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                  <h2 class="info-title">{{"Circulares y documentos oficiales" |uppercase }}</h2>                </button>
              </h2>
            </div>
        
            <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
              <div class="card-body">
                <div class="row text-center">
                  
                  <div class="col-lg-4">
                    <div class="card hecho">
                      <div class="card-body nobedad-b">
                        <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                          alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                        <h2 class="group-title">
                          Convocatoria Elecciones 2023-2024.pdf 
                        </h2>
                      </div>
                      <div class="card-footer">
                        <p class="hecho-f">
                          <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                            <a class="nav-link" target="_blank" href="assets/pdf/Convocatoria-Elecciones-2023-2024.pdf">
                                {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                                translate | uppercase }}
    
                            </a>
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="card hecho">
                      <div class="card-body nobedad-b">
                        <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                          alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                        <h2 class="group-title">
                          Circular 01-2023 Informe vía oficial de postulación a cargos de Junta Directiva 2023-2024
                        </h2>
                      </div>
                      <div class="card-footer">
                        <p class="hecho-f">
                          <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                            <a class="nav-link" target="_blank" href="assets/pdf/eleccion23/Circular_01-2023_Informe_vía_oficial_postulación_2023-2024.pdf">
                                {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                                translate | uppercase }}
    
                            </a>
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="card hecho">
                      <div class="card-body nobedad-b">
                        <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                          alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                        <h2 class="group-title">
                          Circular  02-2023 Fecha limite de potulaciones y solicitud de fotografía
                        </h2>
                      </div>
                      <div class="card-footer">
                        <p class="hecho-f">
                          <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                            <a class="nav-link" target="_blank" href="assets/pdf/eleccion23/Circular_04-2023_solicitud_certificacion_electoral.pdf">
                                {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                                translate | uppercase }}
    
                            </a>
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>
                  
                  <div class="col-lg-4">
                    <div class="card hecho">
                      <div class="card-body nobedad-b">
                        <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                          alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                        <h2 class="group-title">
                          Circular  03-2023 Remisión Calendario Electoral
                        </h2>
                      </div>
                      <div class="card-footer">
                        <p class="hecho-f">
                          <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                            <a class="nav-link" target="_blank" href="assets/pdf/eleccion23/Circular _03-2023_Remision_Calendario_Electoral.pdf">
                                {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                                translate | uppercase }}
    
                            </a>
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="card hecho">
                      <div class="card-body nobedad-b">
                        <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                          alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                        <h2 class="group-title">
                          Circular 04-2023 solicitud certificación electoral
                        </h2>
                      </div>
                      <div class="card-footer">
                        <p class="hecho-f">
                          <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                            <a class="nav-link" target="_blank" href="assets/pdf/eleccion23/Circular_04-2023_solicitud_certificacion_electoral.pdf">
                                {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                                translate | uppercase }}
    
                            </a>
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="card hecho">
                      <div class="card-body nobedad-b">
                        <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                          alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                        <h2 class="group-title">
                          Resolución CE-01-2023 CALENDARIO
                        </h2>
                      </div>
                      <div class="card-footer">
                        <p class="hecho-f">
                          <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                            <a class="nav-link" target="_blank" href="assets/pdf/eleccion23/ResoluciónCE-01-2023CALENDARIO.pdf">
                                {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                                translate | uppercase }}
    
                            </a>
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>
                   
                  <div class="col-lg-4">
                    <div class="card hecho">
                      <div class="card-body nobedad-b">
                        <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                          alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                        <h2 class="group-title">
                          Circular  05-2023 Remisión Reglamento Electoral 2023
                        </h2>
                      </div>
                      <div class="card-footer">
                        <p class="hecho-f">
                          <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                            <a class="nav-link" target="_blank" href="assets/pdf/eleccion23/Circular_05-2023_Remision_Reglamento_Electoral_2023.pdf">
                                {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                                translate | uppercase }}
    
                            </a>
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="card hecho">
                      <div class="card-body nobedad-b">
                        <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                          alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                        <h2 class="group-title">
                          REGLAMENTO DEL PROCESO ELECTORAL 2023
                        </h2>
                      </div>
                      <div class="card-footer">
                        <p class="hecho-f">
                          <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                            <a class="nav-link" target="_blank" href="assets/pdf/eleccion23/REGLAMENTO_PROCESO_ELECTORAL_2023.pdf">
                                {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                                translate | uppercase }}
    
                            </a>
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="card hecho">
                      <div class="card-body nobedad-b">
                        <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                          alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                        <h2 class="group-title">
                          Circular 06-2023 convocatoria ensayos votaciones
                        </h2>
                      </div>
                      <div class="card-footer">
                        <p class="hecho-f">
                          <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                            <a class="nav-link" target="_blank" href="assets/pdf/eleccion23/Circular_06-2023_convocatoria_ensayos_votaciones.pdf">
                                {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                                translate | uppercase }}
    
                            </a>
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>
  
                  <div class="col-lg-4">
                    <div class="card hecho">
                      <div class="card-body nobedad-b">
                        <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                          alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                        <h2 class="group-title">
                          RESOLUCIÓN CE-02-2023 PADRON ELECTORAL
                        </h2>
                      </div>
                      <div class="card-footer">
                        <p class="hecho-f">
                          <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                            <a class="nav-link" target="_blank" href="assets/pdf/eleccion23/RESOLUCIÓN_CE-02-2023_PADRON-ELECTORAL.pdf">
                                {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                                translate | uppercase }}
                            </a>
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="card hecho">
                      <div class="card-body nobedad-b">
                        <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                          alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                        <h2 class="group-title">
                          Circular  07-2023 Remisión del Padrón Electoral 2023
                        </h2>
                      </div>
                      <div class="card-footer">
                        <p class="hecho-f">
                          <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                            <a class="nav-link" target="_blank" href="assets/pdf/eleccion23/Circular_ 07-2023_Padron_Electoral_2023.pdf">
                                {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                                translate | uppercase }}
                            </a>
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="card hecho">
                      <div class="card-body nobedad-b">
                        <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                          alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                        <h2 class="group-title">
                          RESOLUCIÓN CE-03-2023  CAMPAÑA ELECTORAL 2023
                        </h2>
                      </div>
                      <div class="card-footer">
                        <p class="hecho-f">
                          <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                            <a class="nav-link" target="_blank" href="assets/pdf/eleccion23/RESOLUCION_CE-03-2023_ CAMPANIA_2023.pdf">
                                {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                                translate | uppercase }}
                            </a>
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="card hecho">
                      <div class="card-body nobedad-b">
                        <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                          alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                        <h2 class="group-title">
                          Circular  08-2023 Remisión instructivo de votación
                        </h2>
                      </div>
                      <div class="card-footer">
                        <p class="hecho-f">
                          <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                            <a class="nav-link" target="_blank" href="assets/pdf/eleccion23/Circular _08-2023_Remisión_instructivo_votación.pdf">
                                {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                                translate | uppercase }}
                            </a>
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="card hecho">
                      <div class="card-body nobedad-b">
                        <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                          alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                        <h2 class="group-title">
                          Circular  09-2023 CUMPLIMIENTO ARTICULO 12 DEL REGLAMENTO CE-2023
                        </h2>
                      </div>
                      <div class="card-footer">
                        <p class="hecho-f">
                          <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                            <a class="nav-link" target="_blank" href="assets/pdf/eleccion23/Circular_09-2023_CUMPLIMIENTO_ART_12_REGLAMENTO-CE-2023.pdf">
                                {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                                translate | uppercase }}
                            </a>
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="card hecho">
                      <div class="card-body nobedad-b">
                        <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                          alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                        <h2 class="group-title">
                          Circular  10-2023 Cierre de Inscripción y Registro de Postulaciones
                        </h2>
                      </div>
                      <div class="card-footer">
                        <p class="hecho-f">
                          <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                            <a class="nav-link" target="_blank" href="assets/pdf/eleccion23/Circular_10-2023-Cierre_Inscripción-y-Postulaciones.pdf">
                                {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                                translate | uppercase }}
                            </a>
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-4">
                    <div class="card hecho">
                      <div class="card-body nobedad-b">
                        <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                          alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                        <h2 class="group-title">
                          CIRCULAR CE-11-2023 CIERRE DE CAMPAÑA ELECTORAL
                        </h2>
                      </div>
                      <div class="card-footer">
                        <p class="hecho-f">
                          <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                            <a class="nav-link" target="_blank" href="assets/pdf/eleccion23/Circular_11-2023_Cierre_Campaña.pdf">
                                {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                                translate | uppercase }}
                            </a>
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="card hecho">
                      <div class="card-body nobedad-b">
                        <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                          alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                        <h2 class="group-title">
                          CIRCULAR CE-12-2023 ENSAYO GENERAL DE VOTACIONES
                        </h2>
                      </div>
                      <div class="card-footer">
                        <p class="hecho-f">
                          <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                            <a class="nav-link" target="_blank" href="assets/pdf/eleccion23/Circular_12-2023_Ensayo_votaciones.pdf">
                                {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                                translate | uppercase }}
                            </a>
                        </button>
                        </p>
                      </div>
                    </div>
                  </div>
                  
                  
                </div>
                
              </div>
            </div>
          </div>
          <!- - Seccion de Candidatos Rep - ->
          <div class="card">
            <div class="card-header" id="headingRep">
              <h2 class="mb-0">
                <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseRep" aria-expanded="false" aria-controls="collapseRep">
                  <h2>Candidatos República Dominicana</h2>
                </button>
              </h2>
            </div>
            <div id="collapseRep" class="collapse" aria-labelledby="headingRep" data-parent="#accordionExample">
              <div class="card-body">
                <div class="row text-center">
                  <div class="col-lg-12">
                    <h2 class="group-title">
                      Candidata-Candidato a Presidencia
                    </h2>
                  </div>
                  <div class="col-lg-6">
                    <div class="card-body nobedad-b">
                      <img width="100%" height="100%" src="assets/pdf/eleccion23/RD-Ramón Emilio Goris1.jpeg" >
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="card-body nobedad-b">
                      <img width="100%" height="100%" src="assets/pdf/eleccion23/RD-SilviaGarcia.jpeg" >
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <h2 class="group-title">
                      Candidata-Candidato a Secretaría
                    </h2>
                  </div>
                  <div class="col-lg-6">
                    <div class="card-body nobedad-b">
                      <img width="100%" height="100%" src="assets/pdf/eleccion23/RD-Carlos Sanchez Soliman.jpg" >
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="card-body nobedad-b">
                      <img width="100%" height="100%" src="assets/pdf/eleccion23/RD-Martha Perez Campusano.jpg" >
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="card-body nobedad-b">
                      <img width="100%" height="100%" src="assets/pdf/eleccion23/RD_Santiago_Rodriguez.jpeg" >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!- - Seccion de Candidatos El Salvador- ->
          <div class="card">
            <div class="card-header" id="headingTwo">
              <h2 class="mb-0">
                <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  <h2>Candidatos El Salvador</h2>  
                </button>
              </h2>
            </div>
            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
              <div class="card-body">
                  <div class="row text-center">
                    <div class="col-lg-12">
                      <h2 class="group-title">
                        Candidata-Candidato a Vice Presidencia
                      </h2>
                    </div>
                    <div class="col-lg-6">
                      <div class="card-body nobedad-b">
                        <img width="100%" height="100%" src="assets/pdf/eleccion23/ES_AnaArias.jpeg" >
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="card-body nobedad-b">
                        <img width="100%" height="100%" src="assets/pdf/eleccion23/SV_HeidyMira.jpeg" >
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <h2 class="group-title">
                        Candidata-Candidato a Secretaría
                      </h2>
                    </div>
                    <div class="col-lg-6">
                    <div class="card-body nobedad-b">
                      <img width="100%" height="100%" src="assets/pdf/eleccion23/SV_AngelMaravilla.jpg" >
                    </div>
                    <div class="card-body nobedad-b">
                      HD Karina Ivette Sosa de Rodas 
                      <img width="100%" height="100%" src="assets/pdf/eleccion23/noImagen.jpg" >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!- - Seccion de Candidatos Guatemala- ->
          <div class="card">
            <div class="card-header" id="headingThree">
              <h2 class="mb-0">
                <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  <h2>Candidatos Guatemala</h2>
                </button>
              </h2>
            </div>
            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
              <div class="card-body">
                <div class="row text-center">
                  <div class="col-lg-12">
                    <h2 class="group-title">
                      Candidata-Candidato a Vice Presidencia
                    </h2>
                  </div>
                  <div class="col-lg-6">
                    <div class="card-body nobedad-b">
                      <img width="100%" height="100%" src="assets/pdf/eleccion23/GT_IngridBernat.png" >
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="card-body nobedad-b">
                      HD Erwin Eduardo Velásquez Herrera
                      <img width="100%" height="100%" src="assets/pdf/eleccion23/GT_EduardoVelasquez.png" >
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <h2 class="group-title">
                      Candidata-Candidato a Secretaría
                    </h2>
                  </div>
                  <div class="col-lg-6">
                    <div class="card-body nobedad-b">
                      <img width="100%" height="100%" src="assets/pdf/eleccion23/GT_MonicaBronci.jpeg" >
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="card-body nobedad-b">
                      <img width="100%" height="100%" src="assets/pdf/eleccion23/GT_MarioValdeabellano.jpeg" >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!- - Seccion de Candidatos Honduras - ->
          <div class="card">
            <div class="card-header" id="headingHD">
              <h2 class="mb-0">
                <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseHD" aria-expanded="false" aria-controls="collapseHD">
                  <h2>Candidatos Honduras</h2>
                </button>
              </h2>
            </div>
            <div id="collapseHD" class="collapse" aria-labelledby="headingHD" data-parent="#accordionExample">

              <div class="card-body">
                  <div class="row text-center">
                    <div class="col-lg-12">
                      <h2 class="group-title">
                        Candidata-Candidato a Vice Presidencia
                      </h2>
                    </div>
                    <div class="col-lg-6">
                      <div class="card-body nobedad-b">
                        <img width="100%" height="100%" src="assets/pdf/eleccion23/HD_AFICHE-LICONA.jpg" >
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="card-body nobedad-b">
                        HD Yehring Roberto Maldonado
                        <img width="100%" height="100%" src="assets/pdf/eleccion23/noImagen.jpg" >
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <h2 class="group-title">
                        Candidata-Candidato a Secretaría
                      </h2>
                    </div>
                    <div class="col-lg-6">
                    <div class="card-body nobedad-b">
                      <img width="100%" height="100%" src="assets/pdf/eleccion23/HD_AFICHE JIMENEZ.jpg" >
                    </div>
                    <div class="col-lg-6">
                      <div class="card-body nobedad-b">
                        HD Rodimiro Zelaya Cortes
                        <img width="100%" height="100%" src="assets/pdf/eleccion23/noImagen.jpg" >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
            
          </div>
          <!- - Seccion de Candidatos Ni- ->
          <div class="card">
            <div class="card-header" id="headingNi">
              <h2 class="mb-0">
                <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseNi" aria-expanded="false" aria-controls="collapseNi">
                  <h2>Candidatos Nicaragua</h2>
                </button>
              </h2>
            </div>
            <div id="collapseNi" class="collapse" aria-labelledby="headingNi" data-parent="#accordionExample">
              <div class="card-body">
                  <div class="row text-center">
                    <div class="col-lg-12">
                      <h2 class="group-title">
                        Candidata-Candidato a Vice Presidencia
                      </h2>
                    </div>
                    <div class="col-lg-6">
                      <div class="card-body nobedad-b">
                        HD Guillermo Daniel Ortega Reyes	
                        <img width="100%" height="100%" src="assets/pdf/eleccion23/ni_Guillermo-Aida.jpeg" >
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <h2 class="group-title">
                        Candidata-Candidato a Secretaría
                      </h2>
                    </div>
                    <div class="col-lg-6">
                    <div class="card-body nobedad-b">
                      HD Aida Ester Blanco Talavera
                      <img width="100%" height="100%" src="assets/pdf/eleccion23/ni_Guillermo-Aida.jpeg" >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!- - Seccion de Candidatos Pa- ->
          <div class="card">
            <div class="card-header" id="headingPa">
              <h2 class="mb-0">
                <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapsePa" aria-expanded="false" aria-controls="collapsePa">
                  <h2>Candidatos Panamá</h2>
                </button>
              </h2>
            </div>
            <div id="collapsePa" class="collapse" aria-labelledby="headingPa" data-parent="#accordionExample">
              <div class="card-body">
                  <div class="row text-center">
                    <div class="col-lg-12">
                      <h2 class="group-title">
                        Candidata-Candidato a Vice Presidencia
                      </h2>
                    </div>
                    <div class="col-lg-6">
                      <div class="card-body nobedad-b">
                        HD Amado Cerrud Acevedo
                        <img width="100%" height="100%" src="assets/pdf/eleccion23/PA_AmadoCerrud.jpeg" >
                      </div>
                    </div>
                    
                    <div class="col-lg-6">
                      <div class="card-body nobedad-b">
                        <img width="100%" height="100%" src="assets/pdf/eleccion23/PA_JoseRamosReyes.jpeg" >
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <h2 class="group-title">
                        Candidata-Candidato a Secretaría
                      </h2>
                    </div>
                    <div class="col-lg-6">
                    <div class="card-body nobedad-b">
                      HD Giselle de Lourdes Burillo Saiz
                      <img width="100%" height="100%" src="assets/pdf/eleccion23/noImagen.jpg" >
                    </div>
                    <div class="card-body nobedad-b">
                      HD Dorindo Jayan Cortez Marciaga
                      <img width="100%" height="100%" src="assets/pdf/eleccion23/PA_AmadoCerrud.jpeg" >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  
          - ->

        </div>
<!- -  fin   Seccion de Candidatos Rep -->

        <div class="section-title-container">
          <h3 class="page-title">
              Elecciones a Junta Directiva Bancada de Panamá y República Dominicana
          </h3>
      </div>
      
      <div class="accordion" id="accordionExample">
        <!-- Seccion de documentos-->
        <div class="card">
          <div class="card-header" id="headingOne">
            <h2 class="mb-0">
              <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                <h2 class="info-title">{{"Circulares y documentos oficiales   (abrir)" |uppercase }}</h2>                </button>
            </h2>
          </div>
      
          <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
            <div class="card-body">
              <div class="row text-center">
                
                <div class="col-lg-4">
                  <div class="card hecho">
                    <div class="card-body nobedad-b">
                      <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                        alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                      <h2 class="group-title">
                        1 Convocatoria 20 agosto 2024
                      </h2>
                    </div>
                    <div class="card-footer">
                      <p class="hecho-f">
                        <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                          <a class="nav-link" target="_blank" href="assets/pdf/eleccion24/1 Convocatoria 20 agosto 2024.pdf">
                              {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                              translate | uppercase }}
                          </a>
                      </button>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="card hecho">
                    <div class="card-body nobedad-b">
                      <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                        alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                      <h2 class="group-title">
                        2 Reglamento ComisionElectoral
                      </h2>
                    </div>
                    <div class="card-footer">
                      <p class="hecho-f">
                        <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                          <a class="nav-link" target="_blank" href="assets/pdf/eleccion24/2 Reglamento ComisionElectoral.pdf">
                              {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                              translate | uppercase }}
                          </a>
                      </button>
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="card hecho">
                    <div class="card-body nobedad-b">
                      <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                        alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                      <h2 class="group-title">
                        3 resolución 1-2024 comisiónelectoral
                      </h2>
                    </div>
                    <div class="card-footer">
                      <p class="hecho-f">
                        <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                          <a class="nav-link" target="_blank" href="assets/pdf/eleccion24/3 resolución 1-2024 comisiónelectoral .pdf">
                              {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                              translate | uppercase }}
                          </a>
                      </button>
                      </p>
                    </div>
                  </div>
                </div>
                
                <div class="col-lg-4">
                  <div class="card hecho">
                    <div class="card-body nobedad-b">
                      <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                        alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                      <h2 class="group-title">
                        RESOLUCION CE-02-2024 PADRON ELECTORAL AGOSTO REVISADO Y APROBADO 
                      </h2>
                    </div>
                    <div class="card-footer">
                      <p class="hecho-f">
                        <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                          <a class="nav-link" target="_blank" href="assets/pdf/eleccion23/RESOLUCION CE-02-2024 PADRON ELECTORAL AGOSTO REVISADO Y APROBADO (1).pdf">
                              {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                              translate | uppercase }}
                          </a>
                      </button>
                      </p>
                    </div>
                  </div>
                </div>
                

               

          <!-- Seccion de Candidatos Rep - ->
          <div class="card">
            <div class="card-header" id="headingRep">
              <h2 class="mb-0">
                <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseRep" aria-expanded="false" aria-controls="collapseRep">
                  <h2>Candidatos República Dominicana</h2>
                </button>
              </h2>
            </div>

            <div id="collapseRep" class="collapse" aria-labelledby="headingRep" data-parent="#accordionExample">
              <div class="card-body">
                <div class="row text-center">
                  <div class="col-lg-12">
                    <h2 class="group-title">
                      Candidata-Candidato a Presidencia
                    </h2>
                  </div>
                  <div class="col-lg-6">
                    <div class="card-body nobedad-b">
                      <img width="100%" height="100%" src="assets/pdf/eleccion23/RD-Ramón Emilio Goris1.jpeg" >
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="card-body nobedad-b">
                      <img width="100%" height="100%" src="assets/pdf/eleccion23/RD-SilviaGarcia.jpeg" >
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <h2 class="group-title">
                      Candidata-Candidato a Secretaría
                    </h2>
                  </div>
                  <div class="col-lg-6">
                    <div class="card-body nobedad-b">
                      <img width="100%" height="100%" src="assets/pdf/eleccion23/RD-Carlos Sanchez Soliman.jpg" >
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="card-body nobedad-b">
                      <img width="100%" height="100%" src="assets/pdf/eleccion23/RD-Martha Perez Campusano.jpg" >
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="card-body nobedad-b">
                      <img width="100%" height="100%" src="assets/pdf/eleccion23/RD_Santiago_Rodriguez.jpeg" >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          -->
          <!-- Seccion de Candidatos El Salvador- ->
          <div class="card">
            <div class="card-header" id="headingTwo">
              <h2 class="mb-0">
                <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  <h2>Candidatos El Salvador</h2>  
                </button>
              </h2>
            </div>
            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
              <div class="card-body">
                  <div class="row text-center">
                    <div class="col-lg-12">
                      <h2 class="group-title">
                        Candidata-Candidato a Vice Presidencia
                      </h2>
                    </div>
                    <div class="col-lg-6">
                      <div class="card-body nobedad-b">
                        <img width="100%" height="100%" src="assets/pdf/eleccion23/ES_AnaArias.jpeg" >
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="card-body nobedad-b">
                        <img width="100%" height="100%" src="assets/pdf/eleccion23/SV_HeidyMira.jpeg" >
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <h2 class="group-title">
                        Candidata-Candidato a Secretaría
                      </h2>
                    </div>
                    <div class="col-lg-6">
                    <div class="card-body nobedad-b">
                      <img width="100%" height="100%" src="assets/pdf/eleccion23/SV_AngelMaravilla.jpg" >
                    </div>
                    <div class="card-body nobedad-b">
                      HD Karina Ivette Sosa de Rodas 
                      <img width="100%" height="100%" src="assets/pdf/eleccion23/noImagen.jpg" >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!- - Seccion de Candidatos Guatemala - ->
          <div class="card">
            <div class="card-header" id="headingThree">
              <h2 class="mb-0">
                <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  <h2>Candidatos Guatemala     (abrir)</h2>
                </button>
              </h2>
            </div>
            <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
              <div class="card-body">
                <div class="row text-center">
                  <div class="col-lg-12">
                    <h2 class="group-title">
                      Candidata-Candidato a Vice Presidencia
                    </h2>
                  </div>
                  <div class="col-lg-6">
                    <div class="card-body nobedad-b">
                      HD Carlos Alberto Barreda Taracena
                      <img width="100%" height="100%" src="assets/pdf/eleccion23/VC-Carlos-Barreda.JPG" >
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="card-body nobedad-b">
                      HD Karla Lisbeth Gutiérrez Herrera
                      <img width="100%" height="100%" src="assets/pdf/eleccion23/VC-KarlaGutierrez.jpeg" >
                    </div>
                  </div>
                                   
                  
                  <div class="col-lg-12">
                    <h2 class="group-title">
                      Candidata-Candidato a Secretaría
                    </h2>
                  </div>
                  <div class="col-lg-6">
                    <div class="card-body nobedad-b">
                      HD Roberto Rodrigo Armando Castillo Barrientos
                      <img width="100%" height="100%" src="assets/pdf/eleccion23/SC-RobertoCastillo.JPG" >
                    </div>
                  </div>
                  
                  <div class="col-lg-6">
                    <div class="card-body nobedad-b">
                      HD Raúl Romero Segura
                      <img width="100%" height="100%" src="assets/pdf/eleccion23/SC-RaulRomero.jpeg" >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          -->
          <!-- Seccion de Candidatos Honduras - ->
          <div class="card">
            <div class="card-header" id="headingHD">
              <h2 class="mb-0">
                <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseHD" aria-expanded="false" aria-controls="collapseHD">
                  <h2>Candidatos Honduras</h2>
                </button>
              </h2>
            </div>
            <div id="collapseHD" class="collapse" aria-labelledby="headingHD" data-parent="#accordionExample">

              <div class="card-body">
                  <div class="row text-center">
                    <div class="col-lg-12">
                      <h2 class="group-title">
                        Candidata-Candidato a Vice Presidencia
                      </h2>
                    </div>
                    <div class="col-lg-6">
                      <div class="card-body nobedad-b">
                        <img width="100%" height="100%" src="assets/pdf/eleccion23/HD_AFICHE-LICONA.jpg" >
                      </div>
                    </div>
                    <div class="col-lg-6">
                      <div class="card-body nobedad-b">
                        HD Yehring Roberto Maldonado
                        <img width="100%" height="100%" src="assets/pdf/eleccion23/noImagen.jpg" >
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <h2 class="group-title">
                        Candidata-Candidato a Secretaría
                      </h2>
                    </div>
                    <div class="col-lg-6">
                    <div class="card-body nobedad-b">
                      <img width="100%" height="100%" src="assets/pdf/eleccion23/HD_AFICHE JIMENEZ.jpg" >
                    </div>
                    <div class="col-lg-6">
                      <div class="card-body nobedad-b">
                        HD Rodimiro Zelaya Cortes
                        <img width="100%" height="100%" src="assets/pdf/eleccion23/noImagen.jpg" >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
            
          </div>
          <!- - Seccion de Candidatos Ni- ->
          <div class="card">
            <div class="card-header" id="headingNi">
              <h2 class="mb-0">
                <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseNi" aria-expanded="false" aria-controls="collapseNi">
                  <h2>Candidatos Nicaragua</h2>
                </button>
              </h2>
            </div>
            <div id="collapseNi" class="collapse" aria-labelledby="headingNi" data-parent="#accordionExample">
              <div class="card-body">
                  <div class="row text-center">
                    <div class="col-lg-12">
                      <h2 class="group-title">
                        Candidata-Candidato a Vice Presidencia
                      </h2>
                    </div>
                    <div class="col-lg-6">
                      <div class="card-body nobedad-b">
                        HD Guillermo Daniel Ortega Reyes	
                        <img width="100%" height="100%" src="assets/pdf/eleccion23/ni_Guillermo-Aida.jpeg" >
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <h2 class="group-title">
                        Candidata-Candidato a Secretaría
                      </h2>
                    </div>
                    <div class="col-lg-6">
                    <div class="card-body nobedad-b">
                      HD Aida Ester Blanco Talavera
                      <img width="100%" height="100%" src="assets/pdf/eleccion23/ni_Guillermo-Aida.jpeg" >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!- - Seccion de Candidatos Pa - ->
          <div class="card">
            <div class="card-header" id="headingPa">
              <h2 class="mb-0">
                <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapsePa" aria-expanded="false" aria-controls="collapsePa">
                  <h2>Candidatos Panamá</h2>
                </button>
              </h2>
            </div>
            <div id="collapsePa" class="collapse" aria-labelledby="headingPa" data-parent="#accordionExample">
              <div class="card-body">
                  <div class="row text-center">
                    <div class="col-lg-12">
                      <h2 class="group-title">
                        Candidata-Candidato a Vice Presidencia
                      </h2>
                    </div>
                    <div class="col-lg-6">
                      <div class="card-body nobedad-b">
                        HD Amado Cerrud Acevedo
                        <img width="100%" height="100%" src="assets/pdf/eleccion23/PA_AmadoCerrud.jpeg" >
                      </div>
                    </div>
                    
                    <div class="col-lg-6">
                      <div class="card-body nobedad-b">
                        <img width="100%" height="100%" src="assets/pdf/eleccion23/PA_JoseRamosReyes.jpeg" >
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <h2 class="group-title">
                        Candidata-Candidato a Secretaría
                      </h2>
                    </div>
                    <div class="col-lg-6">
                    <div class="card-body nobedad-b">
                      HD Giselle de Lourdes Burillo Saiz
                      <img width="100%" height="100%" src="assets/pdf/eleccion23/noImagen.jpg" >
                    </div>
                    <div class="card-body nobedad-b">
                      HD Dorindo Jayan Cortez Marciaga
                      <img width="100%" height="100%" src="assets/pdf/eleccion23/PA_AmadoCerrud.jpeg" >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>  
          <!-- fin panama -->

        </div>
        

      </div>

      
  </div>
</div>